/* eslint-disable camelcase */
import Pagination from '@/components/pagination';
import TableColumnComp from '@/components/tableColumnComp';
import useFetchApi from '@/hooks/useFetchApi';
import { ITableLayoutData } from '@/models/layout/tableLayout';
import { UserRoleStatusEnum } from '@/models/page/systemSetting';
import { subAccountList } from '@/service/modelTransfer/systemSetting/subAccountList';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { ISystemSettingPopupLayoutProps } from '../layout.mapping';
import { useTranslation } from 'react-i18next';
import { alignCenterStyles, numericStyles } from '@/service/utils';
import { DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import { IBaseRequestData } from '@/models/apiRequest';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '@/constants/datetime';
interface IRequest extends IBaseRequestData {}

type Props = {
    userName?: string;
} & ISystemSettingPopupLayoutProps;

const SubAccountList = ({ onClose, userName }: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState<ITableLayoutData>();
    const [searchData, setSearchData] = useState<IRequest>({
        DateFrom: dayjs().format(DATETIME_FORMAT.YMDHMSS),
        DateTo: dayjs().format(DATETIME_FORMAT.YMDHMSS),
        PartnerId: 0,
        PageNo: 1,
        PageSize: DEFAULT_PAGE_COUNT,
        UserId: 1,
    }); // 紀錄搜尋列表的參數用
    const [requestData, setRequestData] = useState<IRequest>(searchData); // 紀錄 fetch api Request的值
    const fetchHandler = useFetchApi<IRequest, any, any>(
        {
            path: '/subAccountList',
            method: 'FAKE',
        },
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, request }) => {
            let _data: ITableLayoutData;
            if (value) {
                const fakeData = {
                    Data: [
                        {
                            UserName: 'sayaku',
                            Name: 'sayaku',
                            RegisterDate: '2021-11-11',
                            Status: UserRoleStatusEnum.Enable,
                            Agent_M6: 0,
                            Agent_BB: 1,
                            Agent_N8: 2,
                        },
                        {
                            UserName: 'sayaku2',
                            Name: 'sayaku2',
                            RegisterDate: '2021-11-11',
                            Status: UserRoleStatusEnum.Enable,
                            Agent_M6: 0,
                            Agent_BB: 1,
                            Agent_N8: 2,
                        },
                        {
                            UserName: 'sayaku3',
                            Name: 'sayaku3',
                            RegisterDate: '2021-11-11',
                            Status: UserRoleStatusEnum.Enable,
                            Agent_M6: 0,
                            Agent_BB: 1,
                            Agent_N8: 2,
                        },
                    ],
                    Summary: { TotalRecords: 3 },
                };

                _data = subAccountList(fakeData, request.PageNo, request.PageSize);
                setResponseData(_data);
            }
            setIsLoading(false);
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        fetchHandler(requestData);
    }, []);

    const col = useMemo(() => {
        return responseData?.tableHeader || [];
    }, [responseData]);

    const body = useMemo(() => {
        return responseData?.tableBody || [];
    }, [responseData]);

    return (
        <>
            <div className="flex flex-col justify-center">
                <div className="flex justify-between">
                    <div>
                        <span>帳號:</span> <span>{userName}</span>
                    </div>
                    <div>
                        <span>*只顯示"啟用"的子帳號</span>
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <div data-table-container className="m-2 ">
                        <div className="table w-full min-w-[300px]">
                            <div className="table-header-group bg-gray-100 text-xs">
                                <div className="table-row ">
                                    {col
                                        .filter((item) => !item.invisible)
                                        .map((item, i) => {
                                            return (
                                                <div
                                                    key={`table-header-${i}`}
                                                    className={`table-cell p-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                        alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                    }`}>
                                                    {t(item.text)}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="table-row-group">
                                {body.map((hash, pi) => {
                                    return (
                                        <Fragment key={`ff-${pi}`}>
                                            <div className="table-row odd:bg-gray-200 even:bg-slate-50" key={`table-td-tr-${pi}`}>
                                                {col
                                                    .filter((item) => !item.invisible)
                                                    .map((item, i) => {
                                                        return (
                                                            <div
                                                                className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                                    alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                                }`}
                                                                key={`tbody-${item.name}`}>
                                                                <div>
                                                                    <TableColumnComp text={hash[item.name]} style={item.type} />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div data-pagination className="mt-2 flex justify-center">
                        <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord').replace('{0}', `${responseData?.totalRecords || 0}`)}</span>
                        <Pagination
                                key={`member-admain-pagination-${responseData?.totalRecords || 1}`}
                                pageCount={Math.ceil((responseData?.totalRecords || 1) / DEFAULT_PAGE_COUNT)}
                                onPageChange={(e) => {
                                    setSearchData((data) => {
                                        if (data) {
                                            const result = { ...data, PageNo: e.selected + 1 };
                                            fetchHandler(result);
                                            return result;
                                        }

                                        return data;
                                    });
                                }} />
                    </div>
                </div>

                <div className="flex justify-center mt-2">
                    <button
                        className=" m-2 w-24 h-8 bg-affiliate text-white rounded hover:bg-gray-500"
                        onClick={() => {
                            onClose();
                        }}>
                        <label className="px-1 cursor-pointer">{t('Btn_confirm')}</label>
                    </button>
                </div>
            </div>
        </>
    );
};

export default SubAccountList;
