import { IChatMessage, IChatMessageCard, IChatMessageGroup, MESSAGE_TYPE } from '@/models/chatMessage/IChatMessage';
import { RootState } from '@/store/store.config';
import { Avatar, Link } from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export type IChatMessageBoxParams = {
    searchTerm: string;
} & Partial<IChatMessage>;

export const ChatMessageBox = (params: IChatMessageBoxParams & IChatMessage) => {
    const { t } = useTranslation();
    const vChatEndpoint = useSelector((state: RootState) => state.configStore.apiHost?.vchat ?? '');

    const highlightMessage = (message: string, key: string) => {
        if (!key || key.length === 0) {
            return message;
        }
        return message.replaceAll(key, `<span className=' bg-yellow-300'>${key}</span>`);
    };

    const transferToHttpLink = (message: string, searchTerm: string): string => {
        const linkRegexp = RegExp(/(https?:\/\/\S+)/, 'ig');

        if (!linkRegexp.test(message)) {
            return transferToEmo(message || '', searchTerm);
        }

        const temp = message
            .split(linkRegexp)
            .map((msg) => {
                return linkRegexp.test(msg)
                    ? `<a href='${msg}' target="_blank" className="mr-1 ml-1 underline hover:decoration-blue-500 hover:text-blue-500">${highlightMessage(
                          msg,
                          searchTerm,
                      )}</a> `
                    : transferToEmo(msg, searchTerm);
            })
            .join('');

        return temp;
    };

    const transferToEmo = (message: string, searchTerm: string): string => {
        const emojiRegexp = RegExp(/\[e\d+\]/, 'ig');

        if (!emojiRegexp.test(message)) {
            return highlightMessage(message || '', searchTerm);
        }

        const msgRegExp = /[[\]]/gm;
        const nodes = message.split(emojiRegexp).map((msg) => highlightMessage(msg, searchTerm));

        const emos =
            message
                .match(emojiRegexp)
                ?.map((msg) => msg.replace(msgRegExp, ''))
                .map((msg) => `<img className='w-6 h-6' src='${vChatEndpoint}/Emote/0/${msg}.png'></img>`) || [];

        const newMsg = nodes.map((node, idx) => node + (emos[idx] || '')).join('');
        return newMsg;
    };

    const ChatTxt = (params: IChatMessageBoxParams) => {
        return (
            <>
                {params.Message?.split('\n').map((msg, idx) => (
                    <div className=" flex" key={idx}>
                        {parse(transferToHttpLink(msg || '', params.searchTerm))}
                    </div>
                ))}
            </>
        );
    };

    const ChatEmo = (params: IChatMessageBoxParams) => {
        return <img src={params.Message} loading="lazy" decoding="async" className="w-16 h-16" />;
    };

    const ChatPic = (params: IChatMessageBoxParams) => {
        return <img src={params.Message} loading="lazy" decoding="async" className="object-cover w-auto h-auto aspect-auto rounded-lg0" />;
    };

    const ChatCard = (params: IChatMessageBoxParams) => {
        const cardInfo: IChatMessageCard = JSON.parse(params.Message || '');

        return (
            <div className="cursor-pointer w-fit">
                <div className="flex bg-slate-400 rounded-lg p-4 items-center gap-2">
                    <Avatar name={cardInfo.NickName} src={cardInfo.HeadImg} zIndex={100} />
                    <div className="flex-1 flex flex-col justify-center">
                        <span className="text-slate-100">{cardInfo.NickName}</span>
                        <span className="text-xs text-slate-100">{t('Lbl_ChatMessageCardNote')}</span>
                    </div>
                </div>
            </div>
        );
    };

    const ChatFile = (params: IChatMessageBoxParams) => {
        const [url, name, size] = params.Message?.split('|') || [];

        return (
            <Link href={url} isExternal>
                <div className="rounded-lg bg-gray-800 flex gap-4 h-full p-4 w-fit text-slate-100">
                    <span className=" icon icon-profile" />
                    <div className="flex-1 max-w-[80vw] flex flex-col">
                        <span className=" ">{name}</span>
                        <span className="text-xs text-slate-400">{size}</span>
                    </div>
                </div>
            </Link>
        );
    };

    const ChatVideo = (params: IChatMessageBoxParams) => {
        return (
            <video controls autoPlay={false} className="rounded-lg w-auto h-auto max-h-96 aspect-auto">
                <source src={params.Message || ''} type="video/mp4" />
                {t('Msg_ChatMessageVideoNotSupport')}
            </video>
        );
    };

    const ChatAudio = (params: IChatMessageBoxParams) => {
        const [audioUrl] = !!params.Message ? params.Message.split('|') : [''];
        return (
            // <video controls autoPlay={false} className="rounded-lg w-auto h-auto max-h-48 aspect-auto">
            //     <source src={params.Message || ''} type="audio/mp4" />
            //     {t('Msg_ChatMessageVideoNotSupport')}
            // </video>
            <audio controls autoPlay={false}>
                <source src={audioUrl} type="audio/mpeg" />
                {t('Msg_ChatMessageVideoNotSupport')}
            </audio>
        );
    };

    const ChatInvite = (params: IChatMessageBoxParams) => {
        const cardInfo: IChatMessageGroup = JSON.parse(params.Message || '');

        return (
            <div className="cursor-pointer w-fit">
                <div className="flex bg-sky-500 rounded-lg p-4 items-center gap-2">
                    <Avatar name={cardInfo.GroupName} src={cardInfo.GroupImg} />
                    <div className="flex-1 flex flex-col justify-center">
                        <span className="text-slate-100">{cardInfo.GroupName}</span>
                        <span className="text-xs text-slate-100">{t('Lbl_ChatMessageCardNote')}</span>
                    </div>
                </div>
            </div>
        );
    };

    const ChatCall = (params: IChatMessageBoxParams) => {
        return (
            <p className="flex-1">
                {' '}
                <span className=" icon icon-mobile" /> {parse(highlightMessage(params.Message || '', params.searchTerm))}
            </p>
        );
    };

    const genText = (params: IChatMessageBoxParams) => {
        switch (params.MsgType) {
            case MESSAGE_TYPE.txt:
                return <ChatTxt {...params} />;
            case MESSAGE_TYPE.emo:
                return <ChatEmo {...params} />;
            case MESSAGE_TYPE.pic:
                return <ChatPic {...params} />;
            case MESSAGE_TYPE.card:
                return <ChatCard {...params} />;
            case MESSAGE_TYPE.file:
                return <ChatFile {...params} />;

            case MESSAGE_TYPE.video:
                return <ChatVideo {...params} />;
            case MESSAGE_TYPE.audio:
                return <ChatAudio {...params} />;
            case MESSAGE_TYPE.invite:
                return <ChatInvite {...params} />;
            case MESSAGE_TYPE.call:
                return <ChatCall {...params} />;
            default:
                return <ChatTxt {...params} />;
        }
    };

    return <>{genText(params)}</>;
};
