import { PopupModel } from '@/components/popupModal/popupModal';
import { useCopyToClipboardWithToast } from '@/hooks/useCopyToClipboard';
import { useTranslation } from 'react-i18next';
import { Button, Table, Tbody, Td, Th, Thead, Tr, UseDisclosureProps } from '@chakra-ui/react';
import { useCallback } from 'react';

import type { IPromotionLinkDetail } from '..';

export type ISubdomainPopupProps =
    | {
          title?: string;
          domains: IPromotionLinkDetail[];
          onGenQRCode: (domain: string) => void;
      } & UseDisclosureProps;

export const SubdomainPopup = (params: ISubdomainPopupProps) => {
    const [copy] = useCopyToClipboardWithToast();
    const { t } = useTranslation();

    const copyText = useCallback((text: string) => {
        copy(text);
    }, []);

    const copyAll = useCallback(() => {
        const text = params.domains.map((item) => item.Domain).join(',');
        copy(text);
    }, []);

    const openPopup = (text: string) => {
        // setProps((pre) => {
        //     return { ...pre, isOpen: true, domain: text };
        // });
        params.onGenQRCode(text);
    };

    return (
        <>
            <PopupModel {...params} size="3xl">
                <div data-container className="mx-4">
                    <Table variant="striped" colorScheme="blackAlpha">
                        <Thead>
                            <Tr>
                                <Th>
                                    <span className="font-bold text-sm">域名地址</span>
                                </Th>
                                <Th>
                                    <Button className="font-bold" size="sm" onClick={() => copyAll()}>
                                        一键复制
                                    </Button>
                                </Th>
                                <Th>
                                    <span className="font-bold text-sm">链接率</span>
                                </Th>
                                <Th>
                                    <span className="font-bold text-sm">二维码</span>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {params.domains.map((item) => (
                                <Tr key={`tr-key-${item.GroupSequenceID}-${item.Domain}`} className=" text-sm">
                                    <Td>{item.Domain}</Td>
                                    <Td>
                                        <Button colorScheme="orange" onClick={() => copyText(item.Domain)}>
                                            {t('Lbl_copy')}
                                        </Button>
                                    </Td>
                                    <Td> - </Td>
                                    <Td>
                                        <Button variant="link" className="" colorScheme="blue" onClick={() => openPopup(item.Domain)}>
                                            {t('Lbl_PreviewQRCode')}
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            </PopupModel>
        </>
    );
};
