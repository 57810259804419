import { Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';

type Props = {
    title: string;
    content: string[];
};
const Hint = ({ title, content }: Props) => {
    const textContent = useMemo(() => {
        return (
            <div className="pl-6">
                <h1 className="text-lg">{title}</h1>
                <ul className="list-decimal">
                    {content.map((txt, i) => {
                        return <li key={`hint-content-line-${i}`}>{txt}</li>;
                    })}
                </ul>
            </div>
        );
    }, [title, content]);

    return (
        <>
            <Tooltip label={textContent} bg="blackAlpha.700" color="white" placement="auto-start" backdropFilter="auto" backdropBlur="4px" shadow="lg">
                <span className="text-lg text-orange-300 icon icon-question" />
            </Tooltip>
        </>
    );
};

export default Hint;
