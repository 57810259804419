import { ITableColumnData, TableExpandBody, TableExpandFetchData } from '@/models/layout/tableLayout';
import { alignCenterStyles, numericStyles } from '@/service/utils';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
type Props = {
    index: number;
    keyList: ITableColumnData[];
    list?: TableExpandBody[];
    triggerLink?: (requestData?: TableExpandFetchData) => void;
};
export interface IExpandableTableRow {
    toggle: () => void;
    isOpen: boolean;
}
const ExpandableTableRow = forwardRef<IExpandableTableRow, Props>(({ keyList, list = [], index, triggerLink }, ref) => {
    const [isOpen, onToggle] = useState(false);
    useImperativeHandle(
        ref,
        () => ({
            toggle() {
                onToggle((v) => !v);
            },
            isOpen,
        }),
        [isOpen, onToggle],
    );

    const parseValue = useCallback(
        (key: string) => {
            const target = list.find((v) => v.columnKey === key);
            switch (target?.type) {
                case 'text':
                    return <span className={`text-gray-500 ${target.className ? target.className : ''}`}>{target?.value}</span>;
                case 'multiLine-text': {
                    const list = target?.value.toString().split('/n');
                    return list.map((text, i) => {
                        const [value, color] = text.split('/color:');
                        return (
                            <div
                                key={`expand-multi-text-${index}-${i}`}
                                className={`${target.className ? target.className : ''} ${color ? color : 'text-gray-500'}`}>
                                {value}
                            </div>
                        );
                    });
                }
                case 'link':
                    return (
                        <span
                            className={`text-blue-500  cursor-pointer ${target.className ? target.className : ''}`}
                            onClick={() => triggerLink?.(target.requestData)}>
                            {target?.value}
                        </span>
                    );
            }
        },
        [list],
    );
    return (
        <>
            <div className="table-row">
                <div className="table-cell max-h-0" />
                {keyList.map((item, i) => {
                    return (
                        <div
                            className={`table-cell ${numericStyles.includes(item.type) ? 'text-right' : 'text-left'} ${
                                alignCenterStyles.includes(item.type) ? 'text-center' : ''
                            }`}
                            key={`expand-td-${item.name}`}>
                            <div
                                data-active={isOpen}
                                className="py-0 px-6 text-sm font-bold max-h-0 data-active:max-h-[200px] data-active:py-4 overflow-hidden transition-all duration-300">
                                {parseValue(item.name)}
                            </div>
                        </div>
                    );
                })}
            </div>
            {list.length % 2 === 1 && <div className="table-row" />}
        </>
    );
});

export default ExpandableTableRow;
