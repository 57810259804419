import { Center, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, UseDisclosureProps } from '@chakra-ui/react';

export type IPopupModelProps = {
    title?: string;
    titleIconClass?: string;
    children: JSX.Element;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
    styleConfig?: Record<string, any>;
    isCentered?: boolean;
} & UseDisclosureProps;

export const PopupModel = (props: IPopupModelProps) => {
    const { isCentered = true } = props;
    const { isOpen, onClose } = useDisclosure(props);
    return (
        <>
            <Modal onClose={onClose} size={props.size || 'xl'} isOpen={isOpen} isCentered={isCentered} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="bg-gradient-to-r from-neutral-300 to-affiliate text-gray-100 rounded-t">
                        <Center>
                            {props.titleIconClass ? <span className={props.titleIconClass} /> : ''}
                            <Text fontSize="xl">{props.title || ''}</Text>
                        </Center>
                    </ModalHeader>
                    <ModalCloseButton className="text-gray-100" />
                    <ModalBody>
                        <div className=" h-full">{props.children}</div>
                    </ModalBody>
                    {/* <ModalFooter></ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    );
};
