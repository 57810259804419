import { PopupModel } from '@/components/popupModal/popupModal';
import { Button, ButtonGroup, Input, UseDisclosureProps } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export type IUpdateGroupNamePopupProps = {
    groupName: string;
    onSave: (newName: string) => void;
} & UseDisclosureProps;

export const RemarkTypeEnum = {
    Recharge: 2,
    Welfare: 3,
    FirstDepositBenefit: 4,
    Others: 1,
} as const;

export const UpdateGroupNamePopup = (params: IUpdateGroupNamePopupProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [newName, setNewName] = useState<string>(params.groupName);

    const isOnSaveDisabled = () => newName.length < 3 || newName === params.groupName;

    const handelOnSave = () => {
        if (isOnSaveDisabled()) return;
        params.onSave(newName);
    };

    const popUpParams = {
        ...params,
        title: t('Lbl_UpdateRelationGroupName'),
    };

    return (
        <>
            <PopupModel {...popUpParams}>
                <div className="flex-col flex ">
                    <div className="flex-col mt-2 grow flex">
                        <div>
                            <div className="ml-1 flex mb-2 justify-center">
                                <div className=" self-center mr-3">
                                    <label className=" self-center">{t('Th_GroupName')}: </label>
                                </div>
                                <div>
                                    <Input
                                        css=" border-gray-300 border-2 rounded"
                                        maxLength={15}
                                        placeholder={params.groupName}
                                        type="text"
                                        onChange={(e) => {
                                            setNewName(e.target.value);
                                        }}
                                        value={newName}
                                        autoComplete="new-password"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 確認 */}
                        <div className="flex grow justify-center">
                            <div className="ml-1 flex-col">
                                <ButtonGroup gap="2">
                                    <Button colorScheme="gray" onClick={() => params.onClose && params.onClose()}>
                                        {t('Lbl_Button_Cancle')}
                                    </Button>
                                    <Button colorScheme="orange" onClick={() => handelOnSave()} isDisabled={isOnSaveDisabled()}>
                                        {t('Lbl_Button_Confirm')}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupModel>
        </>
    );
};
