import { BettingRateEnum } from '@/models/page/report/IRelationMember';
import { useTranslation } from 'react-i18next';
import { VIPTagComp } from '@/components/vipTagComp/VIPTagComp';

const BettingRateNote = () => {
    const { t } = useTranslation();
    const items = [BettingRateEnum.VIP, BettingRateEnum.HV, BettingRateEnum.MV, BettingRateEnum.LV, BettingRateEnum.NDP];
    const notes = ['7以上', '5-7', '3.5-5', '0-3.5', '0'];

    const thClass = 'border border-slate-300 text-center text-red-500 h-8';
    const tfootClass = 'border border-slate-300 text-left text-red-500 h-12 ';

    return (
        <>
            <table className="border-collapse border border-slate-400 w-[620px]">
                <thead>
                    <tr>
                        {items.map((item, i) => (
                            <th key={`brn-th1-${i}`} className={`${thClass} w-1/5`}>
                                {t(`Lbl_BettingRateEnum_str_${item}`)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {items.map((item, i) => (
                            <td key={`brn-tr1-${i}`} className={`${thClass}`}>
                                {notes[i]}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {items.map((item, i) => (
                            <td key={`brn-tr2-${i}`} className={`${thClass}`}>
                                <VIPTagComp bettingRate={item} />
                            </td>
                        ))}
                    </tr>
                    <tr className=" whitespace-pre-wrap">
                        <td colSpan={2} className={`${tfootClass} `}>
                            <span>表示会员对于下注非常积极,且愿意投入较多资金。显示用户的消费活跃度和忠诚度较高。</span>
                        </td>
                        <td colSpan={2} className={`${tfootClass}`}>
                            <span>表示会员每月下注的金额相对较少，这意味着用户对于下注行为较为保守。</span>
                        </td>
                        <td className={`${tfootClass}`}>
                            <span>表示会员当月无任何储值记录。</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default BettingRateNote;
