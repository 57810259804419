import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeToken } from '@/store/slices/user.slice';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
const User = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userName = useSelector((state: RootState) => state.userStore.userName);
    const dispatch = useDispatch();
    const hasRechargeItems = useSelector((state: RootState) => state.userStore.hasRechargeItems);
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);

    const logout = () => {
        dispatch(removeToken());
        navigate('/logon');
    };

    return (
        <>
            <span className="mx-4 text-lg text-neutral-100 whitespace-nowrap">{userName}</span>
            <Menu isLazy>
                <MenuButton>
                    <span className="text-neutral-100 text-2xl icon icon-user" />
                    {hasRechargeItems && <span className="icon icon-info text-red-500 relative bottom-4 right-2" />}
                    {/* <span className={`mx-2 text-neutral-100 icon icon-keyboard_arrow_down ${hasRechargeItems ? 'ml-0' : ''}`} /> */}
                    <span className={`mx-2  ${hasRechargeItems ? 'ml-0' : ''}`} />
                </MenuButton>
                <MenuList>
                    {permissionList.includes(PermissionsId.PersonalInformation) && (
                        <MenuItem
                            onClick={() => {
                                navigate('/profile');
                            }}>
                            <span className="mx-2 icon icon-profile" />
                            {t('Txt_profile')}
                        </MenuItem>
                    )}
                    {permissionList.includes(PermissionsId.SecurityCenter) && (
                        <MenuItem
                            onClick={() => {
                                navigate('/securitycenter');
                            }}>
                            <span className="mx-2 icon icon-security" />
                            {t('Txt_securityCenter')}
                        </MenuItem>
                    )}
                    {permissionList.includes(PermissionsId.PartnerPasswordManagement) && (
                        <MenuItem
                            onClick={() => {
                                navigate('/passwordmanager');
                            }}>
                            <span className="mx-2 icon icon-keepass" />
                            {t('Txt_PasswordManager')}
                        </MenuItem>
                    )}
                    {permissionList.includes(PermissionsId.RechargeMonitoring) && (
                        <MenuItem
                            onClick={() => {
                                navigate('/rechargemonitor');
                            }}>
                            <span className="mx-2 icon icon-rechargemonitor" />
                            {t('Txt_RechargeMonitor')}
                            {hasRechargeItems && <span className="mx-2 icon icon-info text-red-500" />}
                        </MenuItem>
                    )}
                    {permissionList.includes(PermissionsId.FundDetails) && (
                        <MenuItem
                            onClick={() => {
                                navigate('/funddetails');
                            }}>
                            <span className="mx-2 icon icon-account" />
                            {t('Title_FundDetails')}
                            {hasRechargeItems && <span className="mx-2 icon icon-info text-red-500" />}
                        </MenuItem>
                    )}
                    {/* MenuItems are not rendered unless Menu is open */}
                    {/* <MenuItem>
                        {' '}
                        <span className={'mx-2 icon icon-profile'} />
                        {t('Txt_profile')}
                    </MenuItem>
                    <MenuItem>
                        <span className={'mx-2 icon icon-account'} />
                        {t('Txt_account')}
                    </MenuItem>
                    <MenuItem>
                        <span className={'mx-2 icon icon-news'} />
                        {t('Txt_news')}
                    </MenuItem> */}
                    <MenuItem onClick={() => logout()}>
                        <span className="mx-2 icon icon-exit" />
                        {t('Txt_logout')}
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};

export default User;
