import { Input, Select, Text, Textarea } from '@chakra-ui/react';
import dayjs from 'dayjs';
import Datepicker from 'react-tailwindcss-datepicker';
import { IMemberAdminPageProps } from '../pageConfig';
import { useTranslation } from 'react-i18next';
import useFetchApi from '@/hooks/useFetchApi';
import {
    ContactTypeEnum,
    ContactTypeGroupEnum,
    IMemberContactsRequest,
    IMemberDetailsContacts,
    IMemberDetailsData,
    IReportMemberInfoData,
} from '@/models/page/report/IReportMemberDetails';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { api } from '@/service/api';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType, DialogContentType } from '@/components/dialog/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { IMemberRequestData } from '@/models/apiRequest';
import { ContactForm } from '@/pages/memberAdmin/admin/components/contactForm';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
import Edit66Chat from '../components/edit66Chat';

export enum ActionTypeEnum {
    ADD,
    UPDATE,
    DELETE,
    REPLACE,
}

export interface IMemberContactsData extends IMemberDetailsContacts {
    id: number;
}

const reducer = (state: IMemberContactsData[], action: { type: ActionTypeEnum; payload: IMemberContactsData[] }) => {
    let result = [...state];
    switch (action.type) {
        case ActionTypeEnum.ADD: {
            action.payload.forEach((item) => {
                result.push(item);
            });
            break;
        }
        case ActionTypeEnum.UPDATE: {
            action.payload.forEach((item) => {
                const index = result.findIndex((v) => v.id === item.id);
                index > -1 && (result[index] = item);
            });

            break;
        }
        case ActionTypeEnum.DELETE: {
            action.payload.forEach((item, i, arr) => {
                const index = result.findIndex((v) => v.id === item.id);
                index > -1 && result.splice(index, 1);
            });
            break;
        }
        case ActionTypeEnum.REPLACE: {
            result = action.payload;
            break;
        }
    }
    return result;
};

const init = (list: IMemberDetailsContacts[]) => {
    return list.map((v, i) => ({ ...v, id: Date.now() + i }));
};

const MemberAdminProfilePage = (props: IMemberAdminPageProps) => {
    const { t } = useTranslation();
    const reduxDispatch = useDispatch();
    const [memberDetail, setMemberDetail] = useState<IReportMemberInfoData>();
    const [memberContacts, setContacts] = useState<IMemberDetailsContacts[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [contactsItem, dispatch] = useReducer(reducer, memberContacts, init);
    const detectChangeAndFetchHandler = useFetchApi<IMemberRequestData, IMemberDetailsData>(
        api.memberDetails,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value: resp }) => {
            setIsLoading(false);
            if (!resp?.data?.IsSuccess) {
                return;
            }
            setMemberDetail(resp.data.Data.MemberInfo);
            const sortContacts = resp.data.Data.Contacts.sort((a, b) => {
                if (a.ContactTypeId > b.ContactTypeId) return 1;
                if (a.ContactTypeId < b.ContactTypeId) return -1;
                return 0;
            });
            setContacts(sortContacts);
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        props.setVChatVisible?.(!!memberContacts.find((v) => v.ContactTypeId === ContactTypeEnum.VChat)?.IsUnique);
    }, [props.setVChatVisible, memberContacts]);

    const getVChatUser = useFetchApi<{
        vChatId: string;
        upload: {
            type: ActionTypeEnum;
            payload: IMemberContactsData[];
        };
    }>(
        api.getVChatUser,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value: resp, request, deps: [_dispatch, i18n] }) => {
            setIsLoading(false);
            if (!resp?.data?.IsSuccess) {
                return;
            }
            if (resp.data.Message.includes('Account does not exist') && resp.data.Data === undefined) {
                reduxDispatch(
                    showDialog({
                        title: i18n('Lbl_Button_Info'),
                        content: i18n('Msg_AccountDoestExist'),
                        type: DialogButtonType.Error,
                    }),
                );
            } else {
                reduxDispatch(
                    showDialog({
                        title: i18n('Btn_confirm'),
                        content: <Edit66Chat {...resp.data.Data} />,
                        contentType: DialogContentType.Element,
                        buttons: [
                            {
                                text: i18n('Lbl_Button_Cancle'),
                                type: 'cancel',
                                onClick: () => {},
                            },
                            {
                                text: i18n('Lbl_Button_Confirm'),
                                type: 'confirm',
                                onClick: () => {
                                    _dispatch(request.upload);
                                },
                            },
                        ],
                        buttonsAlign: 'center',
                        type: DialogButtonType.Info,
                    }),
                );
            }
        },
        [dispatch, t],
        (error) => {
            // setResp({ Data: [] });
            setIsLoading(false);
        },
    );

    const [dateValue, setDateValue] = useState<DateValueType>({
        startDate: memberDetail?.Birthday || null,
        endDate: memberDetail?.Birthday || null,
    });

    const [contactsRequest, setContactsRequest] = useState<IMemberContactsRequest>(() => {
        return {
            Contacts: [],
            AgentUserCode: props.AgentUserCode,
            PartnerId: props.MemberPartnerId,
            UserName: props.MemberUserName,
            MemberName: '',
            MemberNickName: '',
            BirthDay: '',
            Remark: '',
            IsMale: null,
        };
    });

    const revalidateMemberDetail = useCallback(() => {
        detectChangeAndFetchHandler({
            DateFrom: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            DateTo: dayjs().format('YYYY-MM-DD'),
            UserName: props.MemberUserName,
            PartnerId: props.MemberPartnerId,
        });
    }, [props]);

    const dispatchDialog = useCallback(() => {
        reduxDispatch(
            showDialog({
                title: t('Lbl_Button_Info'),
                content: t('Txt_updateSuccess'),
                onClose: () => {
                    revalidateMemberDetail();
                    props.Reload && props.Reload();
                },
                type: DialogButtonType.Info,
            }),
        );
    }, [t]);
    const saveMemberContact = useFetchApi<IMemberContactsRequest>(
        api.updateMemberContact,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);
            if (value?.data?.IsSuccess) {
                dispatchDialog();
            }
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        reset();
    }, [memberDetail, props]);

    useEffect(() => {
        setMemberDetail(undefined);
        detectChangeAndFetchHandler({
            DateFrom: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            DateTo: dayjs().format('YYYY-MM-DD'),
            UserName: props.MemberUserName,
            PartnerId: props.MemberPartnerId,
        });
    }, [props]);

    useEffect(() => {
        dispatch({ type: ActionTypeEnum.REPLACE, payload: init(memberContacts) });
        setDateValue({
            startDate: memberDetail?.Birthday || null,
            endDate: memberDetail?.Birthday || null,
        });
    }, [memberContacts]);

    const reset = () => {
        setContactsRequest({
            Contacts: [],
            AgentUserCode: props.AgentUserCode,
            PartnerId: props.MemberPartnerId,
            UserName: props.MemberUserName,
            MemberName: memberDetail?.MemberName || '',
            MemberNickName: memberDetail?.MemberNickName || '',
            BirthDay: memberDetail?.Birthday || '',
            Remark: memberDetail?.Remark || '',
            IsMale: memberDetail?.IsMale || null,
        });
        setDateValue({
            startDate: memberDetail?.Birthday || null,
            endDate: memberDetail?.Birthday || null,
        });
    };

    const save = useCallback(() => {
        setEditable(false);
        const result = contactsItem.map(({ ContactTypeGroupId, ContactTypeId, ContactContent }) => ({ ContactTypeGroupId, ContactTypeId, ContactContent }));
        saveMemberContact({ ...contactsRequest, Contacts: result });
        dispatch({ type: ActionTypeEnum.REPLACE, payload: init(memberContacts) }); // reset
    }, [contactsRequest, contactsItem]);

    const permissionList = useSelector((state: RootState) => state.userStore.permissions);

    const onContactFormChange = useCallback((type: ActionTypeEnum, payload: IMemberContactsData[]) => {
        const _66VChat = payload.find((v) => v.ContactTypeId === ContactTypeEnum.VChat);
        if (_66VChat !== undefined && type === ActionTypeEnum.ADD) {
            _66VChat.ContactContent = _66VChat.ContactContent.toLowerCase();
            getVChatUser({ vChatId: _66VChat.ContactContent, upload: { type, payload } });
        } else {
            dispatch({ type, payload });
        }
    }, []);

    return (
        <>
            <div className="text-lg my-4 px-4 font-bold border-l-8 border-blue-400">
                <span>{t('Th_MemberAdmin_Profile')}</span>
            </div>
            <div className="flex">
                <div className="flex flex-1">
                    <div className="flex flex-col items-start flex-1 p-2 gap-y-2">
                        <div className="flex items-center w-full">
                            <Text className="mr-4 whitespace-nowrap">{t('Txt_name')}</Text>

                            <Input
                                className="flex-1 disabled:bg-gray-300"
                                disabled={!editable}
                                // value={memberDetail?.MemberName}
                                placeholder={memberDetail?.MemberName ?? '-'}
                                defaultValue={memberDetail?.MemberName ?? ''}
                                onChange={(e) => {
                                    setContactsRequest((state) => {
                                        return { ...state, MemberName: e.target.value };
                                    });
                                }}
                            />
                        </div>

                        <div className="flex items-center  w-full">
                            <Text className="mr-4 whitespace-nowrap">{t('Txt_nickName')}</Text>

                            <Input
                                className="flex-1 disabled:bg-gray-300"
                                disabled={!editable}
                                // value={memberDetail?.MemberNickName ?? ''}
                                placeholder={memberDetail?.MemberNickName ?? '-'}
                                defaultValue={memberDetail?.MemberNickName ?? ''}
                                onChange={(e) => {
                                    setContactsRequest((state) => {
                                        return { ...state, MemberNickName: e.target.value };
                                    });
                                }}
                            />
                        </div>

                        <div className="flex items-center  w-full">
                            <Text className="mr-4 whitespace-nowrap">{t('Txt_gender')}</Text>

                            <Select
                                className="flex-1 disabled:bg-gray-300"
                                disabled={!editable}
                                value={contactsRequest.IsMale ?? ''}
                                // defaultValue={memberDetail?.IsMale ?? '-'}
                                onChange={(e) => {
                                    setContactsRequest((state) => {
                                        if (e.target.value === '') {
                                            const result = { ...state };
                                            delete result.IsMale;
                                            return result;
                                        } else {
                                            return { ...state, IsMale: e.target.value };
                                        }
                                    });
                                }}>
                                <option value="">-</option>
                                <option value="True">{t('Txt_man')}</option>
                                <option value="False">{t('Txt_woman')}</option>
                            </Select>
                        </div>

                        <div className="flex items-center  w-full">
                            <Text className="mr-4 whitespace-nowrap">{t('Txt_birthday')}</Text>

                            <Datepicker
                                disabled={!editable}
                                inputClassName="border border-gray-200 focus:ring-0 focus:border-blue-300 disabled:bg-gray-300 disabled:border-transparent"
                                primaryColor="orange"
                                useRange={false}
                                asSingle
                                maxDate={new Date(dayjs().format('YYYY-MM-DD'))}
                                value={dateValue}
                                onChange={(v) => {
                                    setDateValue(v);
                                    setContactsRequest((state) => {
                                        return { ...state, BirthDay: v?.endDate?.toString() ?? '' };
                                    });
                                }}
                                toggleIcon={() => <span className="icon icon-calendar" />}
                            />
                        </div>

                        <div className="flex items-start  w-full">
                            <Text className="mr-4 mt-2 whitespace-nowrap">{t('Lbl_note')}</Text>

                            <Textarea
                                disabled={!editable}
                                className="flex-1 disabled:bg-gray-300"
                                // value={contactsRequest.Remark}
                                defaultValue={memberDetail?.Remark ?? ''}
                                onChange={(e) => {
                                    setContactsRequest((state) => ({ ...state, Remark: e.target.value }));
                                }}
                            />
                        </div>
                    </div>

                    {!editable && permissionList.includes(PermissionsId.BasicInformationEdit) ? (
                        <div data-edit-btn className="flex flex-1 justify-end p-2">
                            <button
                                className="self-start p-2 bg-affiliate text-white rounded hover:bg-gray-500"
                                onClick={() => {
                                    setEditable(true);
                                }}>
                                <label className="px-1 cursor-pointer">{t('Lbl_btn_edit')}</label>
                            </button>
                        </div>
                    ) : (
                        <div className="flex flex-1 justify-end p-2" />
                    )}
                </div>
                <div className="border-r border-gray-400" />
                <div className="flex-1 p-2">
                    <div className="flex flex-col">
                        <ContactForm
                            editable={editable}
                            contactType={ContactTypeGroupEnum.PersonalContact}
                            contactItems={contactsItem}
                            onChanged={onContactFormChange}
                        />
                        <ContactForm
                            editable={editable}
                            contactType={ContactTypeGroupEnum.LiveAccount}
                            contactItems={contactsItem}
                            onChanged={onContactFormChange}
                        />
                    </div>
                </div>
            </div>

            {editable ? (
                <div className="flex justify-center">
                    <button className="self-end m-4 p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={save}>
                        <label className="px-1 cursor-pointer">{t('Txt_save')}</label>
                    </button>
                    <button
                        className="self-end m-4 p-2 bg-affiliate text-white rounded hover:bg-gray-500"
                        onClick={() => {
                            setEditable(false);
                            reset();
                            dispatch({ type: ActionTypeEnum.REPLACE, payload: init(memberContacts) }); // reset
                        }}>
                        <label className="px-1 cursor-pointer">{t('Lbl_Button_Cancle')}</label>
                    </button>
                </div>
            ) : (
                <></>
            )}

            <LoadingMask visible={isLoading} />
        </>
    );
};

export default MemberAdminProfilePage;
