import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import { ITextBoardProp } from '@/models/page/dashboard/IDashboard';

export const TextBoard = (props: ITextBoardProp) => {
    dayjs.extend(duration);
    const endDateOfThisMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    const nextMonth = dayjs().add(1, 'month').startOf('month');
    let remainingTime = dayjs.duration(nextMonth.diff(dayjs())).asDays();
    remainingTime = Math.floor(remainingTime);

    return (
        <div className="flex divide-x-2 divide-gray-200 text-lg">
            <div className="ml-2 font-bold">
                剩余 <span className=" text-blue-500">{remainingTime}</span> 天
            </div>
            {/* <div className={'ml-2 pl-2'}>
                本期佣金统计截止 <span className="font-bold">{endDateOfThisMonth}</span>
            </div> */}
            <div className="ml-2 pl-2">
                资料统计至本月 <span className="font-bold">({dayjs(props.LastUpdatedDate).format('YYYY-MM-DD HH:mm')})</span>
            </div>
        </div>
    );
};
