import { MemberDetailsLayoutProps } from '..';
import { statisticRequestTypeMapping, useMemberStatisticsData } from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import { IMemberDetailStatisticRechargeData } from '@/models/page/report/IReportMemberDetails';
import dayjs from 'dayjs';
import { numberComma } from '@/service/utils';
import Pagination from '@/components/pagination';
import { DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import LoadingMask from '@/components/loadingMask/loadingMask';

// 充值
const MemberDetailsRecharge = ({ memberDetail, requestData }: MemberDetailsLayoutProps) => {
    const [statistics, fetchHandler, isLoading] = useMemberStatisticsData<IMemberDetailStatisticRechargeData>({
        memberPartnerId: memberDetail.PartnerId,
        memberUserName: memberDetail.UserName,
        queryDateRange: {
            startDate: requestData.DateFrom,
            endDate: requestData.DateTo,
        },
    });
    const { t } = useTranslation();
    const [currentPage, setPage] = useState(1);

    useEffect(() => {
        fetchHandler({
            StatisticType: statisticRequestTypeMapping.Recharge,
            PartnerId: memberDetail.PartnerId,
            UserName: memberDetail.UserName,
            DateFrom: requestData.DateFrom,
            DateTo: requestData.DateTo,
            PageNo: currentPage,
            PageSize: DEFAULT_PAGE_COUNT,
        });
    }, [currentPage]);

    return (
        <>
            {statistics && statistics?.TotalRecord > 0 && (
                <Table className="table">
                    <Thead className="table" style={{ width: 'calc( 100% - 1em )' }}>
                        <Tr>
                            <Th className="w-[35%]">{t('Lbl_RechargeTime')}</Th>
                            <Th>
                                <div className="text-right">{t('Th_balance')}</div>
                            </Th>
                            <Th>{t('Th_tradeNo')}</Th>
                            <Th className="w-[20%]">{t('Th_note')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody className="block overflow-y-auto max-h-[200px] ">
                        {statistics &&
                            statistics.Data.map((entry, index) => (
                                <Tr key={index} className="table w-full table-fixed">
                                    <Td className="w-[30%]">{dayjs(entry.RechargeTime).format('YYYY-MM-DD HH:mm:ss')}</Td>
                                    <Td>
                                        <div className="text-right">
                                            {'$' + numberComma(FloatHelper.floatFixed(parseFloat(entry.Amount.toString()), RoundType.Floor, 0))}
                                        </div>
                                    </Td>
                                    <Td>
                                        <Text fontSize="sm">{entry.TransactionNo}</Text>
                                    </Td>
                                    <Td className="w-[20%]">{entry.Remark}</Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            )}

            {!isLoading && statistics && statistics?.TotalRecord <= 0 && (
                <div className="my-4 flex justify-center">
                    <span className="text-3xl text-gray-400 icon icon-notification">{t('Lbl_NoRecord')}</span>
                </div>
            )}
            <div className="flex justify-center m-2">
                <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord', { '0': statistics?.TotalRecord || 0 })}</span>
                <Pagination
                    pageCount={Math.ceil((statistics?.TotalRecord || 1) / DEFAULT_PAGE_COUNT)}
                    onPageChange={(e) => {
                        setPage(e.selected + 1);
                    }}
                />
            </div>

            <LoadingMask visible={isLoading} />
        </>
    );
};

export default MemberDetailsRecharge;
