// import IMenuProps from "../../models/components/IMenuProps";
import { NavLink, RouteObject } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
    routes: RouteObject[];
};

const MenuComp = (props: Props) => {
    const { t } = useTranslation();

    const { routes } = props;

    return (
        <>
            <ul className=" flex ">
                {routes.map((route) => (
                    <li className="self-center mx-2 text-base " key={route.path}>
                        <NavLink to={route.path!}>
                            {(params) => {
                                return (
                                    <div
                                        key={route.path}
                                        data-active={params.isActive}
                                        className="p-2 border-b-2 border-affiliate text-neutral-100 hover:border-amber-400 hover:text-amber-400 data-active:text-amber-400 data-active:border-amber-400">
                                        <span className={`mx-2 icon icon-${route.path}`} />
                                        <span className="font-semibold">{t(`Nav_${route.path}`)}</span>
                                    </div>
                                );
                            }}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default MenuComp;
