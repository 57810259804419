import LoadingMask from '@/components/loadingMask/loadingMask';
import TitleLabel from '@/components/titleLabel';
import useFetchApi from '@/hooks/useFetchApi';
import { IQRCodePopupProps, QRCodePopup } from '@/pages/PromotionLink/qrCodePopup/qrCodePopup';
import { api } from '@/service/api';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, useDisclosure, Tooltip } from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatAdminTabs } from '../chatAdminTabs';
import { BaseTablePopup, IBasePopupProps, IColInfo, ICOL_INFO_ENUM } from '../components/baseTablePopup';
import EditChatGroupListPopup from '../components/editChatGroupListPopup';
import CreateAndEditChat from './createAndEditChat';
import cn from 'mxcn';
import { RootState } from '@/store/store.config';
import { useSelector } from 'react-redux';
import { PermissionsId } from '@/models/permissions';
import { PARTNERS_CONFIGS } from '@/assets/config/common/partner.config';
import _ from 'lodash';

export type IGroupChatInfo = {
    CreatorName: string;
    GroupId: number;
    GroupName: string;
    GroupAdmins: IVChatFriend[];
    Remark: string;
};

export type IRegisterFriend = {
    Remark: string;
} & IVChatFriend;

export type IVChatFriend = {
    NickName: string;
    VChatId: string;
};

export type PartnerUser = {
    AgentUserCode: string;
    Partner: number;
};

export type IRegisterCodeInfoModel = {
    BrandMemberCount: number;
    VChatMemberCount: number;
    RegisterCode: string;
    RegisterCodeId: number;
    GroupChatInfos: IGroupChatInfo[];
    RegisterFriends: IRegisterFriend[];
    Remark: string;
    LiveStreamingName: string;
    ParterUsers: PartnerUser[];
    DisplayName: string;
};

export type IRegisterCodeInfoResponse = {
    RegisterCodeInfoModels: IRegisterCodeInfoModel[];
    OwnerVChatId: string;
    OwnerNickName: string;
    DomainUrl: string;
};

export enum PageMode {
    Main,
    Create,
    Edit,
}
export type IProps = {
    children: JSX.Element;
    permission: PermissionsId;
};

const PermissionComp = (props: IProps) => {
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    return <>{permissionList.includes(props.permission) ? props.children : <></>}</>;
};

const ToolTipComp = (props: { content: string }): JSX.Element => {
    return (
        <>
            <Tooltip label={props.content}>
                <div className="ml-1 whitespace-nowrap overflow-hidden text-ellipsis">{props.content}</div>
            </Tooltip>
        </>
    );
};

export const ChatManagePage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState<IRegisterCodeInfoResponse | undefined>(undefined);

    const [props, setProps] = useState<IQRCodePopupProps>({
        title: t('Lbl_GenQRCode'),
        domain: '',
        isOpen: false,
        onClose: () => {
            setProps((pre) => {
                return { ...pre, isOpen: false };
            });
        },
        onSave: () => {
            downPic('gen-QR');
        },
    });

    const [tableProps, setTableFriendProps] = useState<IBasePopupProps<IRegisterFriend | IGroupChatInfo>>({
        title: '',
        data: [],
        cols: [] as IColInfo<IRegisterFriend | IGroupChatInfo>[],
        onClose: () => {
            setTableFriendProps((pre) => {
                return { ...pre, isOpen: false };
            });
        },
    });

    const tableCol = useMemo(
        () => [
            { label: 'Lbl_serialId', name: 'Id' },
            { label: 'Th_RegistrationCode', name: 'RegisterCode' },
            { label: 'Lbl_nickName', name: 'DisplayName' },
            { label: 'Th_Remark', name: 'Remark' },
            { label: 'Lbl_Studio', name: 'Studio' },
            { label: 'Lbl_AssociatedAgent', name: 'AssociatedAgent' },
            { label: 'Lbl_RegisterFriendCount', name: 'RegisterFriendCount' },
            { label: 'Lbl_GroupChatCount', name: 'GroupChatCount' },
            { label: 'Lbl_VChatMemberCount', name: 'VChatMemberCount' },
            { label: 'Lbl_BrandMemberCount', name: 'BrandMemberCount' },
            { label: 'Lbl_QRCode', name: 'QRCode' },
            { label: 'Lbl_optional', name: 'optional' }, // 下個phase會做
        ],
        [],
    );

    const triggerGetRegisterCodeHandler = useFetchApi<any, IRegisterCodeInfoResponse, IRegisterCodeInfoResponse>(
        api.getVChatRegisterCode,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            if (value.data.IsSuccess) {
                setPageData(value.data.Data);
            }
            setIsLoading(false);
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        triggerGetRegisterCodeHandler({});
    }, []);

    const downPic = useCallback((id: string) => {
        // Generate download with use canvas and stream
        const canvas: HTMLCanvasElement = document.getElementById(id) as HTMLCanvasElement;
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `DQR-${Date.now()}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }, []);

    const openQRCodePopup = (text: string) => {
        setProps((pre) => {
            return { ...pre, isOpen: true, domain: text };
        });
    };

    const onClickFriend = (items: IRegisterFriend[]) => {
        setTableFriendProps((pre) => ({
            ...pre,
            title: t('Lbl_RegisterFriend'),
            data: items,
            cols: [
                {
                    label: 'Th_VChatNicknameAndID',
                    name: 'NickName',
                    type: ICOL_INFO_ENUM.Component,
                    displayComponent: (item: IRegisterFriend) => <>{`${item.NickName}(${item.VChatId})`}</>,
                },
                {
                    label: 'Th_Remark',
                    name: 'Remark',
                    type: ICOL_INFO_ENUM.Text,
                },
            ] as IColInfo<IRegisterFriend | IGroupChatInfo>[],
            isOpen: true,
        }));
    };

    const onClickGroup = (items: IGroupChatInfo[]) => {
        setTableFriendProps((pre) => ({
            ...pre,
            title: t('Lbl_RegisterGroupChat'),
            data: items,
            cols: [
                {
                    label: 'Lbl_GroupChatName',
                    name: 'GroupName',
                    type: ICOL_INFO_ENUM.Text,
                },
                {
                    label: 'Lbl_GroupOwner',
                    name: 'CreatorName',
                    type: ICOL_INFO_ENUM.Text,
                },
                {
                    label: 'Lbl_GroupAdmin',
                    name: 'GroupAdmins',
                    type: ICOL_INFO_ENUM.Component,
                    displayComponent: (item: IGroupChatInfo) => {
                        return (
                            <div className="flex flex-col gap-2">
                                {item.GroupAdmins.map((it) => (
                                    <span key={`igi-${item.GroupId}-${it.VChatId}`}>
                                        {it.NickName}({it.VChatId})
                                    </span>
                                ))}
                            </div>
                        );
                    },
                },
                {
                    label: 'Th_Remark',
                    name: 'Remark',
                    type: ICOL_INFO_ENUM.Text,
                },
            ] as IColInfo<IRegisterFriend | IGroupChatInfo>[],
            isOpen: true,
        }));
    };

    const genQRCodeUrl = (registerCode: string) => {
        return `${pageData?.DomainUrl}/download-app/registration/${registerCode}`;
    };

    const editChatGroupListPopupProps = useDisclosure();

    const [pageMode, setPageMode] = useState(PageMode.Main);
    const [registerCodeId, setRegisterCodeId] = useState(0);
    const goMainFn = useCallback(() => {
        setPageMode(PageMode.Main);
        triggerGetRegisterCodeHandler({});
    }, []);

    const genPartnerAccounts = (accounts: PartnerUser[]) => {
        const mapping = _.groupBy(accounts, (ac) => ac.Partner);

        return (
            <>
                {PARTNERS_CONFIGS.map((config) =>
                    !!mapping[config.id] ? (
                        <div key={config.id} data-tt={config.id} className="flex">
                            <div>{t(`Lbl_Partner_${config.text}`)}:</div>
                            <ToolTipComp content={mapping[config.id].map((item) => item.AgentUserCode).join(', ')} />
                        </div>
                    ) : (
                        <Fragment key={config.id} />
                    ),
                )}
            </>
        );
    };

    return (
        <>
            <LoadingMask visible={isLoading} />
            <ChatAdminTabs />
            <div className="my-4">
                <TitleLabel>
                    <>
                        <span
                            className={cn({ 'cursor-pointer hover:text-orange-300': pageMode !== PageMode.Main })}
                            onClick={() => {
                                if (pageMode !== PageMode.Main) {
                                    setPageMode(PageMode.Main);
                                }
                            }}>
                            {t('Nav_chat-manage')}
                        </span>
                        {pageMode !== PageMode.Main && (
                            <>
                                <span>/</span>
                                <span>{pageMode === PageMode.Create ? t('Lbl_CreateRegistrationCode') : t('Lbl_UpdateRegistrationCode')}</span>
                            </>
                        )}
                    </>
                </TitleLabel>
            </div>

            {pageMode === PageMode.Main && (
                <>
                    <div>
                        {!!pageData ? (
                            <>
                                <div className="flex justify-between items-center">
                                    <div className="m-5 rounded-md border-slate-300 border-2 p-3 flex flex-col w-fit">
                                        <div className=" text-sm">{t('Lbl_ChatAdminNameTitle')}</div>
                                        <div className=" text-lg font-bold">{`${pageData?.OwnerNickName}(${pageData?.OwnerVChatId})`}</div>
                                    </div>

                                    <div>
                                        <PermissionComp permission={PermissionsId.EditChatGroupList}>
                                            <button
                                                className="self-end p-2 mr-4 bg-affiliate text-white rounded hover:bg-gray-500"
                                                onClick={() => {
                                                    editChatGroupListPopupProps.onOpen();
                                                }}>
                                                <label className="px-1 cursor-pointer">{t('Lbl_EditChatGroupList')}</label>
                                            </button>
                                        </PermissionComp>
                                        <PermissionComp permission={PermissionsId.CreateRegisterCode}>
                                            <button
                                                className="self-end p-2 mr-4 bg-affiliate text-white rounded hover:bg-gray-500"
                                                onClick={() => {
                                                    setRegisterCodeId(0);
                                                    setPageMode(PageMode.Create);
                                                }}>
                                                <label className="px-1 cursor-pointer">{t('Lbl_CreateRegistrationCode')}</label>
                                            </button>
                                        </PermissionComp>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="m-5">
                        <div>
                            <TableContainer>
                                <Table variant="striped" colorScheme="blackAlpha">
                                    <Thead>
                                        <Tr>
                                            {tableCol.map((item) => {
                                                return (
                                                    <Th key={`aff-th-${item.label}`}>
                                                        <span className="font-bold">{t(item.label)}</span>
                                                    </Th>
                                                );
                                            })}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {!!pageData &&
                                            pageData.RegisterCodeInfoModels.length > 0 &&
                                            pageData.RegisterCodeInfoModels.map((item, idx) => (
                                                <Tr key={`tr-key-${item.RegisterCode}`}>
                                                    <>
                                                        <Td>{idx + 1}</Td>
                                                        <Td>{item.RegisterCode}</Td>
                                                        <Td maxW="60">
                                                            <ToolTipComp content={item.DisplayName} />
                                                        </Td>
                                                        <Td maxW="48">
                                                            <ToolTipComp content={item.Remark} />
                                                        </Td>
                                                        <Td maxW="60">
                                                            <ToolTipComp content={item.LiveStreamingName} />
                                                        </Td>
                                                        <Td maxW="48">{genPartnerAccounts(item.ParterUsers)}</Td>

                                                        <Td>
                                                            <Button
                                                                variant="link"
                                                                className="mr-2"
                                                                colorScheme="blue"
                                                                onClick={() => onClickFriend(item.RegisterFriends)}>
                                                                {item.RegisterFriends.length}
                                                            </Button>
                                                        </Td>
                                                        <Td>
                                                            <Button
                                                                variant="link"
                                                                className="mr-2"
                                                                colorScheme="blue"
                                                                onClick={() => onClickGroup(item.GroupChatInfos)}>
                                                                {item.GroupChatInfos.length}
                                                            </Button>
                                                        </Td>

                                                        <Td>{item.VChatMemberCount}</Td>
                                                        <Td>{item.BrandMemberCount}</Td>
                                                        <Td>
                                                            <Button
                                                                variant="link"
                                                                className="mr-2"
                                                                colorScheme="blue"
                                                                onClick={() => downPic(`download-qr-code-${idx}`)}>
                                                                {t('Lbl_DownloadQRCode')}
                                                                <QRCodeCanvas
                                                                    id={`download-qr-code-${idx}`}
                                                                    className="hidden invisible"
                                                                    value={genQRCodeUrl(item.RegisterCode)}
                                                                    fgColor="black"
                                                                    size={160}
                                                                />
                                                            </Button>
                                                            <Button
                                                                variant="link"
                                                                className=""
                                                                colorScheme="blue"
                                                                onClick={() => openQRCodePopup(genQRCodeUrl(item.RegisterCode))}>
                                                                {t('Lbl_PreviewQRCode')}
                                                            </Button>
                                                        </Td>
                                                        <Td>
                                                            <PermissionComp permission={PermissionsId.EditRegisterCode}>
                                                                <span
                                                                    className="icon icon-edit-profile bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                                    onClick={() => {
                                                                        setRegisterCodeId(item.RegisterCodeId);
                                                                        setPageMode(PageMode.Edit);
                                                                    }}
                                                                />
                                                            </PermissionComp>
                                                        </Td>
                                                    </>
                                                </Tr>
                                            ))}

                                        {!pageData || !pageData.RegisterCodeInfoModels || pageData.RegisterCodeInfoModels.length === 0 ? (
                                            <Tr>
                                                <Td colSpan={tableCol.length} className="text-center" textAlign="center">
                                                    {t('Lbl_NoRecord')}
                                                </Td>
                                            </Tr>
                                        ) : (
                                            <></>
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <QRCodePopup {...props} key={`QR-${props.isOpen}`} />
                    <BaseTablePopup {...tableProps} key={`BT-${props.isOpen}`} size="3xl" />
                    <EditChatGroupListPopup
                        key={editChatGroupListPopupProps.isOpen ? 'EditChatGroupListPopup_0' : 'EditChatGroupListPopup_1'}
                        {...editChatGroupListPopupProps}
                    />
                </>
            )}

            {(pageMode === PageMode.Edit || pageMode === PageMode.Create) && (
                <CreateAndEditChat {...{ pageMode, setIsLoading, registerCodeId, goMain: goMainFn }} />
            )}
        </>
    );
};
