import { tableLayoutConfig } from '@/assets/config/layout/layout.config';
import { HistoryTableLayoutEnum } from '@/components/historyModel/layout';
import { useTranslation } from 'react-i18next';
import useTransferTableValue from '@/hooks/useTransferTableValue';
import { APIModel, IBaseRequestData } from '@/models/apiRequest';
import { DireactableKeyList, ITableBodyLayoutData, ITableLayoutData, TableColumnStyle, TableExpandFetchData } from '@/models/layout/tableLayout';
import { api } from '@/service/api';
import { alignCenterStyles, downloadCSV, numericStyles, toFirstLowerCase, toFirstUpperCase } from '@/service/utils';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Dispatch, forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IReportProps } from '.';
import ExpandableTableRow, { IExpandableTableRow } from './expandableTableRow';
import { SortingStatus } from '@/models/sorting';
import TableColumnComp from '@/components/tableColumnComp';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';

export enum ModelType {
    Member,
    Commission,
}

type Props = {
    data?: ITableLayoutData;
    oriRequestData?: IBaseRequestData;
    direactableKeyList?: DireactableKeyList[];
    setProps?: Dispatch<IReportProps>;
    onModelTrigger?(type: ModelType, api: APIModel, data: any, layoutId: HistoryTableLayoutEnum): void;
    sortingTrigger?(props: { SortColumn: number; IsDescendingSort: boolean }): void;
    switchId?: number;
    isLoaded?: boolean;
    needFold?: boolean;
};
export interface ITableComp {
    exportCSV(fileName: string): void;
}
const TableComponent = forwardRef<ITableComp | undefined, Props>(
    ({ data, oriRequestData, direactableKeyList, setProps, onModelTrigger, sortingTrigger, switchId = -1, isLoaded, needFold }, ref) => {
        const { t } = useTranslation();
        const permissionList = useSelector((state: RootState) => state.userStore.permissions);

        const hasExtra = data?.extraData;
        useEffect(() => {
            setExpandIconkey([]);
        }, [data]);

        const parseHeader = () => {
            const result = data?.tableHeader || [];
            if (switchId > -1 && hasExtra) {
                return result.filter((v) => v.switch === switchId);
            }
            return result;
        };
        const parsebody = () => {
            let result = data?.tableBody || [];
            if (switchId > -1 && hasExtra) {
                const start = data?.extraData[switchId - 1] || 0;
                const end = (data?.extraData[switchId] || 0) + start;
                result = result.slice(start, end);
            }
            return result;
        };
        const col = parseHeader();
        const body = parsebody();
        const footer = data?.tableFooter;
        const hasFooter = data?.hasFooter;
        const sortableFields = data?.sortableFields || [];

        const parseDireactable = useCallback(
            (hash: ITableBodyLayoutData, key: string, style: TableColumnStyle) => {
                if (direactableKeyList) {
                    const target = direactableKeyList.find((item) => item.key === key);
                    const showPopup = !target?.showPopupPermission || permissionList.includes(target.showPopupPermission);
                    return !!target && showPopup ? (
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => {
                                if (target.isPopupModel) {
                                    const value = (oriRequestData && oriRequestData['PartnerId']) + ''; // 先暫時寫死啦
                                    onModelTrigger &&
                                        onModelTrigger(
                                            ModelType.Member,
                                            api.memberDetails,
                                            { userName: hash[key], partnerId: hash['partnerId'] || value },
                                            HistoryTableLayoutEnum.NONE,
                                        );
                                    // popupModel
                                } else if (setProps) {
                                    const targetPage = tableLayoutConfig.find((item) => item.pageId === target.directLayout);
                                    const forBeforeTrensfer = target.payload?.forBeforeTrensfer?.map((key) => ({ key, value: hash[key] + '' }));
                                    const forBeforeFetch = target.payload?.forBeforeFetch?.map((key) => {
                                        const forOriRequestKey = toFirstUpperCase(key);
                                        let value = undefined;
                                        if (hash[key] !== undefined) {
                                            value = hash[key];
                                        } else {
                                            value = oriRequestData && oriRequestData[forOriRequestKey as keyof IBaseRequestData];
                                        }
                                        return { key, value: value };
                                    });
                                    const titleLabelKey = target.payload?.forTitleLabelKey ? hash[target.payload?.forTitleLabelKey] : undefined;

                                    setProps({
                                        currentPageId: targetPage!.pageId,
                                        api: targetPage!.api,
                                        pageName: targetPage!.pageName,
                                        headerType: targetPage!.headerType,
                                        search: targetPage!.search,
                                        headerText: targetPage!.headerText,
                                        direactableKeyList: targetPage!.direactableKeyList,
                                        payload: { forBeforeTrensfer, forBeforeFetch, titleLabelKey },
                                        isShowPagging: targetPage!.isShowPagging,
                                        isTriggerBySubMenu: false,
                                        needFold: targetPage!.needFold,
                                        noteComp: targetPage?.noteComp,
                                    });
                                }
                            }}>
                            <TableColumnComp text={hash[key]} style={style} />
                        </span>
                    ) : (
                        <> <TableColumnComp text={hash[key]} style={style} /></>
                    );
                }

                return <><TableColumnComp text={hash[key]} style={style} /></>;
            },
            [direactableKeyList, oriRequestData],
        );

        const parseFooterStyle = useCallback((text: string | number, style: TableColumnStyle) => {
            return <> <TableColumnComp text={text} style={style} /></>;
        }, []);

        const expandRefs = useRef<Map<number, IExpandableTableRow>>(new Map());
        const [expandIconkey, setExpandIconkey] = useState<boolean[]>([]);
        const expandableTableRowTriggerLink = useCallback(
            (hash: ITableBodyLayoutData, requestData?: TableExpandFetchData) => {
                // hash是來自於response的資料(但key的開頭是小寫)
                // oriRequestData則是來自於搜尋欄位的資料(但key的開頭是大寫)
                const resultRequest = requestData?.requestKey
                    .map((key) => {
                        const value = hash[toFirstLowerCase(key)] || oriRequestData![key as keyof IBaseRequestData];
                        return { [key]: value };
                    })
                    .reduce((a, b) => {
                        return { ...a, ...b };
                    });

                onModelTrigger && onModelTrigger(ModelType.Commission, requestData!.src, resultRequest, requestData!.layoutId);
            },
            [oriRequestData],
        );

        const transferTableValue = useTransferTableValue();

        const transferCSV = useCallback(
            (fileName: string) => {
                const csvHeader = col
                    .filter((item) => !item.invisible)
                    .map((item, i) => {
                        return t(item.text);
                    });

                const csvBody = body.map((hash, pi) => {
                    return col
                        .filter((item) => !item.invisible)
                        .map((item, i) => {
                            return transferTableValue(hash[item.name], item.type);
                        });
                });

                const csvfooter =
                    body.length > 0 && !!footer && hasFooter
                        ? col
                              .filter((item) => !item.invisible)
                              .map((item, index) => {
                                  return index === 0 ? t('Lbl_total-summary') : !!footer[item.name] ? transferTableValue(footer[item.name], item.type) : '';
                              })
                        : [];

                const result = [csvHeader, ...csvBody, csvfooter];

                downloadCSV(result, fileName);
            },
            [col, body, footer, transferTableValue],
        );

        useImperativeHandle(ref, () => ({
            exportCSV(fileName: string) {
                transferCSV(fileName);
            },
        }));

        const sortingStatusStyle = useCallback((status: SortingStatus) => {
            switch (status) {
                case SortingStatus.ASC:
                    return 'icon-sort-asc';
                case SortingStatus.DESC:
                    return 'icon-sort-desc';
                default:
                    return 'icon-sort';
            }
        }, []);

        return (
            <>
                {/* <Tooltip hasArrow label="點擊表格標題複製為CSV格式" bg="red.600" placement="top-end"> */}
                <div data-table-container>
                    <div className="table w-full min-w-[300px]  border-2 border-solid">
                        <div className="table-header-group bg-gray-100 text-xs">
                            <div className="table-row ">
                                {needFold && <div className="table-cell text-left p-6" key="table-header--1" />}
                                {col
                                    .filter((item) => !item.invisible)
                                    .map((item, i) => {
                                        const sortingItem = sortableFields.find((v) => v.name === item.name);
                                        return (
                                            <div
                                                key={`table-header-${i}`}
                                                className={`table-cell p-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                    !!sortingItem ? ' cursor-pointer' : ''
                                                } ${alignCenterStyles.includes(item.type) ? 'text-center' : ''}`}
                                                onClick={() => {
                                                    if (sortingItem) {
                                                        const result =
                                                            sortingItem.status === SortingStatus.NONE || sortingItem.status === SortingStatus.ASC
                                                                ? true
                                                                : false;

                                                        const props = { SortColumn: sortingItem.sortKey, IsDescendingSort: result };
                                                        sortingTrigger?.(props);
                                                    }
                                                }}>
                                                {!!sortingItem ? (
                                                    <div className="flex">
                                                        <div
                                                            className={`${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                                alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                            }`}>
                                                            {t(item.text)}
                                                        </div>
                                                        {!!sortingItem ? (
                                                            <div
                                                                className={`${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                                    alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                                }`}>
                                                                <span className={`ml-1 icon ${sortingStatusStyle(sortingItem.status)}`} />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : (
                                                    <span>{t(item.text)}</span>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="table-row-group">
                            {body.map((hash, pi) => {
                                const hasExpand =
                                    needFold &&
                                    data?.tableExpandBody &&
                                    data?.tableExpandBody[pi].length > 0 &&
                                    permissionList.includes(PermissionsId.CommissionReportDiff);
                                return (
                                    <Fragment key={`ff-${pi}`}>
                                        <div
                                            className="table-row odd:bg-gray-200 even:bg-slate-50"
                                            key={`table-td-tr-${pi}`}
                                            onClick={() => {
                                                if (hasExpand) {
                                                    expandRefs.current.get(pi)?.toggle();
                                                    setExpandIconkey((arr) => {
                                                        arr[pi] = !expandRefs.current.get(pi)!.isOpen;
                                                        return [...arr];
                                                    });
                                                }
                                            }}>
                                            {needFold && (
                                                <div className="w-[20px] table-cell py-4 px-6" key={`td-fold-${pi}`}>
                                                    {hasExpand && (!!expandIconkey[pi] ? <ChevronDownIcon /> : <ChevronRightIcon />)}
                                                </div>
                                            )}
                                            {col
                                                .filter((item) => !item.invisible)
                                                .map((item, i) => {
                                                    return (
                                                        <div
                                                            className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                                alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                            }`}
                                                            key={`tbody-${item.name}`}>
                                                            {parseDireactable(hash, item.name, item.type)}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        {hasExpand && (
                                            <ExpandableTableRow
                                                index={pi}
                                                key={`expandableTableRow_${pi}`}
                                                keyList={col.filter((item) => !item.invisible)}
                                                triggerLink={(requestData?: TableExpandFetchData) => expandableTableRowTriggerLink(hash, requestData)}
                                                list={data?.tableExpandBody && data?.tableExpandBody.length > 0 ? data!.tableExpandBody![pi] : []}
                                                ref={(ref) => {
                                                    if (ref) {
                                                        expandRefs.current.set(pi, ref!);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </div>

                        {body.length > 0 && !!footer && hasFooter && (
                            <div className="table-footer-group bg-gray-300 text-xs font-bold">
                                <div className="table-row">
                                    {needFold && <div className="w-[20px] table-cell text-left p-4 px-6" key="table-footer--1" />}
                                    {col
                                        .filter((item) => !item.invisible)
                                        .map((item, index) => {
                                            return (
                                                <div
                                                    key={`table-footer-${index}`}
                                                    className={`table-cell py-4 px-5 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                        alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                    }`}>
                                                    {index === 0
                                                        ? t('Lbl_total-summary')
                                                        : !!footer[item.name] && parseFooterStyle(footer[item.name], item.type)}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>

                    {!isLoaded && body.length <= 0 && (
                        <div className="my-4 flex justify-center">
                            <span className="text-5xl text-gray-400 icon icon-notification">{t('Lbl_NoRecord')}</span>
                        </div>
                    )}
                </div>
                {/* </Tooltip> */}
            </>
        );
    },
);

export default TableComponent;
