import TitleLabel from '@/components/titleLabel';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { IResetPasswordModelProps, ResetPasswordModel } from './popup';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { ProfileTabs } from '../profileTabs/profileTabs';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '@/store/slices/user.slice';

export type IResetPasswordModel = {
    OldPassWord: string;
    NewPassWord: string;
};

const SecurityCenterPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const logoutCallback = useCallback(() => {
        dispatch(removeToken());
        navigate('/logon');
    }, []);

    const dispatchDialog = useCallback(() => {
        dispatch(
            showDialog({
                title: t('Lbl_Button_Info'),
                content: t('Txt_updateSuccessAndLogout'),
                onClose: logoutCallback,
                type: DialogButtonType.Info,
            }),
        );
    }, [t, logoutCallback]);

    const dispatchErrorDialog = useCallback(() => {
        dispatch(
            showDialog({
                title: t('Lbl_Button_Info'),
                content: t('Msg_Error_WrongOldPassword'),
                type: DialogButtonType.Info,
            }),
        );
    }, [t]);

    let request: IResetPasswordModel = {
        OldPassWord: '',
        NewPassWord: '',
    };

    const detectChangeAndFetchHandler = useFetchApi<IResetPasswordModel, any, IResetPasswordModel>(
        api.resetPassword,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            if (value.data.IsSuccess) {
                dispatchDialog();
                setIsOpen(false);
            } else {
                dispatchErrorDialog();
            }
            setIsLoading(false);
            return value.data.Data;
        },
        [request],
        (error) => {
            setIsLoading(false);
        },
    );

    const handleOnSave = (oldPassword: string, newPassword: string) => {
        request = {
            OldPassWord: oldPassword,
            NewPassWord: newPassword,
        };
        detectChangeAndFetchHandler(request);
    };

    const props: IResetPasswordModelProps = {
        title: t('Lbl_UpdateLoginPassword'),
        titleIconClass: 'text-lg text-gray-100 icon icon-edit-password',
        isOpen: isOpen,
        onClose: () => {
            setIsOpen(false);
        },
        onSave: handleOnSave,
    };

    return (
        <>
            <ProfileTabs />
            <div className="my-4">
                <TitleLabel>{t('Txt_securityCenter')}</TitleLabel>
            </div>
            <LoadingMask visible={isLoading} />
            <div className="flex-col ml-4">
                <div className=" font-bold">{t('Msg_ResetPassword_Info')}</div>
                <div className="w-[620px] bg-slate-200 p-2 mt-3  rounded-lg">
                    <div className="flex items-center">
                        <div className="basis-1/6">
                            <div className="">
                                <span className="bg-gradient-to-r  text-7xl  text-gray-500 icon icon-keepass" />
                            </div>
                        </div>
                        <div className="flex-col items-center basis-2/3">
                            <div className="font-bold ">{t('Lbl_UpdateLoginPassword')}</div>
                            <div className=" mt-1">{t('Msg_ResetPassword_Info')}</div>
                        </div>
                        <div className="basis-1/6">
                            <button className="p-2 bg-affiliate text-white rounded hover:bg-gray-500 w-30" onClick={() => setIsOpen(true)}>
                                <label className="px-1 cursor-pointer">{t('Lbl_UpdatePassword')}</label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ResetPasswordModel {...props} key={`${isOpen}`} />
        </>
    );
};

export default SecurityCenterPage;
