import { TableColumnStyle } from '@/models/layout/tableLayout';
import { numberComma, parseCommissionType } from '@/service/utils';
import { VIPTagComp } from '../vipTagComp/VIPTagComp';
import { useTranslation } from 'react-i18next';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { NumberHighlight } from '../numberHighlight/numberHighlight';
import { BettingRateEnum } from '@/models/page/report/IRelationMember';
import { Tooltip, useToast } from '@chakra-ui/react';

type Props = {
    text: string | number;
    style: TableColumnStyle;
};

const TableColumnComp = ({ text, style }: Props) => {
    const { t } = useTranslation();
    const temp = parseCommissionType(text, style);
    const toast = useToast();

    switch (style) {
        case TableColumnStyle.Text:
            return (
                <>
                    <div className="max-w-md">{text}</div>
                </>
            );
        case TableColumnStyle.CommaNumericWithText: {
            const [key, _text] = text.toString().split('_with_');
            const num = numberComma(FloatHelper.floatFixed(parseFloat(key), RoundType.Floor, 0));
            return (
                <>
                    <span className="text-right">
                        <span>{num}</span>
                        <span className="ml-2">{_text}</span>
                    </span>
                </>
            );
        }
        case TableColumnStyle.CommaNumericWithIcon: {
            const [key, icon] = temp.toString().split('_with_');
            const num = numberComma(FloatHelper.floatFixed(parseFloat(key), RoundType.Floor, 0));
            return (
                <>
                    <span className="text-right">
                        <span>{num}</span>
                        <span className={`ml-2 icon icon-${icon} text-red-500`} />
                    </span>
                </>
            );
        }
        case TableColumnStyle.CommaNumericWithSymbol:
            return (
                <>
                    <NumberHighlight value={temp as number} />
                </>
            );
        case TableColumnStyle.CommaNumericWithNegativeSymbol: {
            const negativeSymbolVisible = temp < 0;
            const comma = numberComma(FloatHelper.floatFixed(parseFloat(temp.toString()), RoundType.Floor, 0));
            const num = negativeSymbolVisible ? <span className="text-red-500">{comma.toString()}</span> : <>{comma}</>;
            return <>{num}</>;
        }
        case TableColumnStyle.Partner:
            return <>{t(temp as string)}</>;
        case TableColumnStyle.TextI18N:
            return <>{t(temp as string)}</>;
        case TableColumnStyle.TextI18NWithColor: {
            const [key, color] = temp.toString().split('_with_');
            return <span className={color}>{t(key as string)}</span>;
        }
        case TableColumnStyle.TextWithColor: {
            const [value, color] = text.toString().split('/color:');
            return <div className={`font-bold ${color ? color : 'text-gray-500'}`}>{value}</div>;
        }
        case TableColumnStyle.Icon: {
            return <span className={`icon icon-${text} text-red-500`} />;
        }
        case TableColumnStyle.VIPRank: {
            return (
                <span data-enum={text}>
                    <VIPTagComp bettingRate={text as BettingRateEnum} />
                </span>
            );
        }
        case TableColumnStyle.TextWithCopy: {
            return (
                <>
                    <span className="mr-1">{text}</span>
                    {text !== undefined && text !== '' && text !== 0 && (
                        <Tooltip label="复制">
                            <span
                                className="cursor-pointer icon icon-copy"
                                onClick={() => {
                                    navigator.clipboard.writeText(text + '').then(() => {
                                        toast({
                                            title: '复制成功',
                                            position: 'top-right',
                                            status: 'success',
                                            isClosable: true,
                                        });
                                    });
                                }}
                            />
                        </Tooltip>
                    )}
                </>
            );
        }
        default:
            return <>{temp}</>;
    }
};

export default TableColumnComp;
