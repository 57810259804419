import TitleLabel from '@/components/titleLabel';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { ProfileTabs } from '../profileTabs/profileTabs';
import { IBaseRequestData } from '@/models/apiRequest';
import { baseRequest } from '@/models/apiRequest/baseRequest';
import { TableSearch, TableSearchEnum, TableSearchOption } from '@/models/layout/tableLayout';
import { numberToCommaWithPad } from '@/service/utils';
import SearchColumn from '@/components/searchArea/searchColumn';
import { getEnableConfig, PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { RootState } from '@/store/store.config';
import { Button, Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { RemarkTypeEnum } from '@/pages/recharge/rechargePopup';
import Pagination from '@/components/pagination';
import { RechargeMonitorLogPopup } from './rechargeMonitorLogPopup';
import { IRechargeItemModel, IRechargePageModel, IRechargeQueryModel, RechagerTaskStatusEnum } from '@/models/page/recharge/IRechargeMonitor';
import cn from 'mxcn';
import { DATETIME_FORMAT } from '@/constants/datetime';

export type IRechargeUpdateModel = {
    AgentUserId: number;
    ErrorMessage: string;
    RetryTimes: number;
    TaskStatus: number;
    RechargeOrderId: number;
};

export interface IRechargeRequestData extends IBaseRequestData {
    TaskStatusList: string[];
}

type IDetailPopupParam = {
    details?: IRechargeItemModel;
    isOpen: boolean;
};

const RechargeMonitorPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [detailPopupParam, setDetailPopupParam] = useState<IDetailPopupParam>({
        details: undefined,
        isOpen: false,
    });
    const [pageData, setPageData] = useState<IRechargePageModel>({ RechargeOrders: [], TotalRecord: 0 });

    const intervalList: number[] = [5, 10, 30];
    const [intervalSec, setIntervalSec] = useState<number>(intervalList[1]);
    const [currentSec, setCurrentSec] = useState<number>(0);

    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) =>
        state.userStore.agentPartners.filter((item) => {
            const isPartnerEnable = getEnableConfig()
                .map((v) => v.id)
                .includes(item.PartnerId);
            return isPartnerEnable;
        }),
    ); // 取全部AgentUserIdList列表
    const allowMultiple: number[] = [RemarkTypeEnum.Welfare, RemarkTypeEnum.Others];

    const taskStatusList: TableSearchOption[] = Object.values(RechagerTaskStatusEnum).map((val) => ({
        id: val,
        text: t(`Txt_RechagerTaskStatus_${val}`),
    }));

    const [queryModel, setQueryModel] = useState<IRechargeQueryModel>({
        PartnerId: 0,
        DownLineList: agentUserList.map((item) => item.AgentUserId),
        TaskStatus: [RechagerTaskStatusEnum.Pending, RechagerTaskStatusEnum.Processsing, RechagerTaskStatusEnum.Failed].map((item) => item),
        PageNo: 1,
        PageSize: 10,
    });

    const requestData = useMemo<IRechargeRequestData>(
        () => ({
            ...baseRequest,
            UserIdList: agentUserList.map((item) => item.AgentUserId.toString()),
            TaskStatusList: queryModel.TaskStatus.map((item) => `${item}`),
        }),
        [],
    );

    const search = useMemo<TableSearch[][]>(() => {
        return [
            [
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'partnerId',
                    label: t('Th_partnerId'),
                    placeholder: t('Lbl_All'),
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.MULTI_SELECT,
                    matchKey: 'userIdList',
                    label: t('Th_agentUserList'),
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.MULTI_SELECT_CUSTOME,
                    matchKey: 'taskStatusList',
                    label: t('Th_status'),
                    placeholder: t('Lbl_All'),
                    selectOptions: taskStatusList,
                    defaultSelectOptions: queryModel.TaskStatus.map((item) => item),
                },
            ],
        ];
    }, [t]);

    const hanldeOnSearch = (data: any) => {
        if (Object.hasOwn(data, 'userIdList') && data['userIdList']?.length === 0) {
            dispatch(
                showDialog({
                    title: '警告',
                    content: '必须选一个所属代理',
                    type: DialogButtonType.Warning,
                }),
            );
            return;
        }

        if (Object.hasOwn(data, 'taskStatusList') && data['taskStatusList']?.length === 0) {
            dispatch(
                showDialog({
                    title: '警告',
                    content: '必须选一个状态',
                    type: DialogButtonType.Warning,
                }),
            );
            return;
        }

        setQueryModel((pre) => ({
            ...pre,
            PartnerId: Number(data['partnerId']),
            DownLineList: (data['userIdList'] as string[]).map((item) => Number(item)),
            TaskStatus: (data['taskStatusList'] as string[]).map((item) => Number(item)),
            PageNo: 1,
        }));
    };

    const detachGetRechargeItems = useFetchApi<IRechargeQueryModel, IRechargePageModel, IRechargeQueryModel>(
        api.getRechargeOrders,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);

            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                setPageData(value.data.Data);
            } else {
            }
        },
        [queryModel],
        (error) => {
            setIsLoading(false);
        },
    );

    const detachGetRechargeItemsNoLoading = useFetchApi<IRechargeQueryModel, IRechargePageModel, IRechargeQueryModel>(
        api.getRechargeOrders,
        ({ value }) => {
            return value;
        },
        ({ value }) => {
            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                setPageData(value.data.Data);
            } else {
            }
        },
        [queryModel],
    );

    const onPageChange = (page: number) => {
        setQueryModel((pre) => ({ ...pre, PageNo: page + 1 }));
    };

    useEffect(() => {
        detachGetRechargeItems(queryModel);
    }, [queryModel]);

    useEffect(() => {
        if (!intervalSec || intervalSec === 0) {
            return;
        }

        const id = setTimeout(() => {
            setCurrentSec((pre) => (pre + 1) % intervalSec);
        }, 1000);

        if (currentSec === 0) {
            detachGetRechargeItemsNoLoading(queryModel);
        }

        return () => {
            clearTimeout(id);
        };
    }, [intervalSec, queryModel, currentSec]);

    const detachUpdateRechargeItemStatus = useFetchApi<IRechargeUpdateModel, any, IRechargeUpdateModel>(
        api.updateRechargeOrders,
        ({ value }) => {
            return value;
        },
        ({ value: { data } }) => {
            setIsLoading(false);
            if (data.IsSuccess) {
                detachGetRechargeItems(queryModel);
            } else {
            }
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    const handleUpdateItemStatus = (item: IRechargeItemModel, status: typeof RechagerTaskStatusEnum.Pending | typeof RechagerTaskStatusEnum.IGNORE) => {
        let message = '';
        switch (status) {
            case RechagerTaskStatusEnum.Pending: {
                message = t('Msg_Recharge_Warring_Resend');
                break;
            }
            case RechagerTaskStatusEnum.IGNORE: {
                message = t('Msg_Recharge_Warring_Ignore');
                break;
            }
            default:
                message = t('Msg_UpdateCheck');
                break;
        }

        dispatch(
            showDialog({
                title: t('Lbl_Button_Warring'),
                content: message,
                type: DialogButtonType.Info,
                onConfirm: () => {
                    const updateModel: IRechargeUpdateModel = {
                        AgentUserId: item.AgentUserId,
                        ErrorMessage: item.ErrorMessage,
                        RetryTimes: item.RetryTimes,
                        TaskStatus: status,
                        RechargeOrderId: item.RechargeOrderId,
                    };
                    detachUpdateRechargeItemStatus(updateModel);
                    detachGetRechargeItemsNoLoading(queryModel);
                },
            }),
        );
    };

    const handleDetailClick = (item: IRechargeItemModel) => {
        setDetailPopupParam((pre) => ({ ...pre, isOpen: true, details: item }));
    };

    const col = useMemo(
        () => [
            { label: 'Lbl_serialId', name: 'Id' },
            { label: 'Lbl_CreatedDate', name: 'DateCreated' },
            { label: 'Th_partnerId', name: 'PartnerId' },
            { label: 'Th_agentNickName', name: 'AgentNickName' },
            { label: 'Lbl_MemberAccount', name: 'UserName' },
            { label: 'Lbl_TransactionNo', name: 'TransactionNo' },
            { label: 'Lbl_ParentCategory', name: 'RemarkType' },
            { label: 'Lbl_ChildCategory', name: 'CategoryId' },
            { label: 'Lbl_UpAmount', name: 'Amount' },
            { label: 'Lbl_Multiple', name: 'Multiple' },
            { label: 'Th_status', name: 'TaskStatus' },
            { label: 'Lbl_RetryTimes', name: 'RetryTimes' },
            { label: 'Lbl_LatestUpdatedDate', name: 'DateUpdated' },
            { label: 'Lbl_ExecuteLog', name: 'ExecuteLog' },
            { label: 'Lbl_optional', name: 'Operator' },
        ],
        [],
    );

    const styleObject = {
        bgColor: 'white',
        borderColor: 'whiteAlpha.50',
        focusBorderColor: 'black',
        color: 'gray.500',
        width: '100px',
    };

    const hintContents = [
        t('Msg_ReportRetain7days'),
        'USDT实际转换RMB 的代充金额，请以"代充明细"为准。',
        '资金密码错误、Pin Number Not Match：此为代充密码输入错误，请略过此代充，重新建立一笔正确密码的代充。',
        'SSO Token Kicked Out：此为错误因有人登陆品牌后台，造成当前机器人登入失败无法代充。',
        '该会员账号代充操作频繁,请1分钟后再试!：同一品牌同一会员必须间隔一分钟以上才可代充',
        '超出代充额度上限：请联络品牌方, 提升代充额度上限',
        '错误码429：品牌方系统异常, 可多试几次',
        '执行失败：代充机器人执行失败，请联络相关人员。',
    ];

    return (
        <>
            <ProfileTabs />
            <div className="my-4">
                <TitleLabel>{t('Txt_RechargeMonitor')}</TitleLabel>
            </div>
            <LoadingMask visible={isLoading} />

            <div className="flex flex-col">
                <SearchColumn defaultValue={requestData} search={search} handler={hanldeOnSearch} layout="justify-start" isButtonNewLine={false} />

                <div className="mt-6 mx-4 flex  self-end justify-end">
                    <div className="self-center text-center px-4">
                        <label className=" text-base whitespace-nowrap">
                            {`${t('Lbl_Interval')}(${intervalSec - currentSec < 0 ? '-' : (intervalSec - currentSec + '').padStart(2, '0')}) : `}
                        </label>
                    </div>

                    <Select
                        defaultValue={intervalSec}
                        {...styleObject}
                        onChange={(e) => {
                            setIntervalSec(Number(e.target.value));
                        }}>
                        {intervalList.map((item) => (
                            <option key={`interval-${item}`} value={item}>
                                {`${item} ${t('Lbl_Interval_Secs')}`}
                            </option>
                        ))}
                    </Select>
                </div>
            </div>

            <div data-container className="mx-4">
                <TableContainer>
                    <Table variant="striped" colorScheme="blackAlpha">
                        <Thead>
                            <Tr>
                                {col.map((item) => (
                                    <Th key={`userInfo-th-${item.label}`}>
                                        <span className="font-bold">{t(item.label)}</span>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {!!pageData &&
                                pageData.RechargeOrders.map((item, idx) => (
                                    <Tr key={`tr-key-${item.TransactionNo}-${item.DateCreated}-${item.RechargeOrderId}`}>
                                        <Td>{(queryModel.PageNo - 1) * queryModel.PageSize + idx + 1}</Td>
                                        <Td>{dayjs(item.DateCreated).format('YYYY/MM/DD HH:mm:ss')}</Td>
                                        <Td>{t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[item.PartnerId].text}`)}</Td>
                                        <Td>{item.AgentNickName}</Td>
                                        <Td>{item.UserName}</Td>
                                        <Td>{item.TransactionNo}</Td>
                                        <Td>{t(`Lbl_RechargeType_${item.RemarkType}`)}</Td>
                                        <Td>{t(`Lbl_RechargeCategoryType_${item.CategoryId}`)}</Td>
                                        <Td isNumeric>{numberToCommaWithPad(FloatHelper.floatFixed(item.Amount, RoundType.Floor, 2), 2)}</Td>
                                        <Td isNumeric>{allowMultiple.includes(item.RemarkType) ? item.Multiple : '-'}</Td>
                                        <Td>
                                            <span className={cn(item.TaskStatus === RechagerTaskStatusEnum.Failed && 'text-red-500 font-bold')}>
                                                {t(`Txt_RechagerTaskStatus_${item.TaskStatus}`)}
                                            </span>
                                        </Td>
                                        <Td>{item.RetryTimes}</Td>
                                        <Td>{dayjs(item.DateUpdated).format(DATETIME_FORMAT.YMDHMS)}</Td>
                                        <Td>
                                            {item.TaskStatus === RechagerTaskStatusEnum.Failed ? (
                                                <Button colorScheme="red" variant="link" onClick={() => handleDetailClick(item)}>
                                                    {t('Lbl_CheckError')}
                                                </Button>
                                            ) : (
                                                <Button colorScheme="blue" variant="link" onClick={() => handleDetailClick(item)}>
                                                    {t('Lbl_CheckLook')}
                                                </Button>
                                            )}
                                        </Td>
                                        <Td>
                                            {item.TaskStatus === RechagerTaskStatusEnum.Failed ? (
                                                <>
                                                    <Button
                                                        colorScheme="blue"
                                                        variant="link"
                                                        onClick={() => handleUpdateItemStatus(item, RechagerTaskStatusEnum.Pending)}>
                                                        {t('Lbl_ReSend')}
                                                    </Button>
                                                    <Button
                                                        colorScheme="blue"
                                                        variant="link"
                                                        onClick={() => handleUpdateItemStatus(item, RechagerTaskStatusEnum.IGNORE)}>
                                                        {t('Lbl_Ignore')}
                                                    </Button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Td>
                                    </Tr>
                                ))}

                            {!!pageData && pageData.RechargeOrders.length === 0 ? (
                                <Tr>
                                    <Td fontSize="2xl" colSpan={col.length} className="text-center" textAlign="center">
                                        {t('Lbl_NoRecord')}
                                    </Td>
                                </Tr>
                            ) : (
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
                <div data-pagination className="mt-2 flex justify-center">
                    <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord', { '0': pageData.TotalRecord || 0 })}</span>

                    <Pagination
                        defaultPage={queryModel.PageNo - 1}
                        pageCount={Math.max(Math.ceil((pageData.TotalRecord || 0) / 10), 1)}
                        onPageChange={(e) => {
                            onPageChange(e.selected);
                        }}
                    />
                </div>
            </div>

            <RechargeMonitorLogPopup
                key={`popup-recharge-${detailPopupParam.isOpen}`}
                isOpen={detailPopupParam.isOpen}
                details={detailPopupParam.details}
                onClose={() => setDetailPopupParam((pre) => ({ ...pre, isOpen: false }))}
            />

            <div data-note className="ml-4 mb-4 text-xs">
                <ul className="ml-4 list-disc">
                    {hintContents.map((text, i) => {
                        return (
                            <li key={`note-${i}`} className="text-red-400 whitespace-pre-line">
                                {text}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

export default RechargeMonitorPage;
