import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { IconButton, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';

export type IPasswordInputProp = {
    placeHolder: string;
    onChange: (password: string) => void;
};

export const PasswordInputComp = (params: IPasswordInputProp) => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const getIcon = (show: boolean) => {
        if (show) {
            return (
                <IconButton
                    color="GrayText"
                    colorScheme="blackAlpha"
                    variant="ghost"
                    aria-label="show"
                    h="1.75rem"
                    size="sm"
                    onClick={handleClick}
                    icon={<ViewIcon />}
                />
            );
        } else {
            return (
                <IconButton
                    color="GrayText"
                    colorScheme="blackAlpha"
                    variant="ghost"
                    aria-label="hide"
                    h="1.75rem"
                    size="sm"
                    onClick={handleClick}
                    icon={<ViewOffIcon />}
                />
            );
        }
    };
    return (
        <>
            <InputGroup size="md">
                <Input
                    css="w-[150px] border-gray-300 border-2 rounded"
                    maxLength={20}
                    placeholder={params.placeHolder}
                    type={show ? 'text' : 'password'}
                    onChange={(e) => {
                        params.onChange(e.target.value);
                    }}
                    autoComplete="new-password"
                />
                <InputRightElement width="4.5rem">{getIcon(show)}</InputRightElement>
            </InputGroup>
        </>
    );
};
