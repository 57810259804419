import { getEnableConfig, PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import DateRangeWithInput from '@/components/searchArea/dateRangeWithInput';
import TitleLabel from '@/components/titleLabel';
import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import type { IBaseRequestData } from '@/models/apiRequest';
import { baseRequest } from '@/models/apiRequest/baseRequest';
import { TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '@/service/api';
import { numberComma, toFirstUpperCase } from '@/service/utils';
import { type IQRCodePopupProps, QRCodePopup } from './qrCodePopup/qrCodePopup';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import type { RootState } from '@/store/store.config';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { useCopyToClipboardWithToast } from '@/hooks/useCopyToClipboard';
import { type ISubdomainPopupProps, SubdomainPopup } from './subdomainPopup/subdomainPopup';

enum ColType {
    TEXT,
    NUMBER,
    OPTIONAL,
    PARTNER,
    COPY,
    DOMAIN,
}

export type IPromotionLinkPara = {
    PartnerId: number;
};

export type IPromotionLink = {
    AgentNickName: string;
    PartnerId: number;
    GroupSequenceID: number;
    Remark: string;
    RechargeNum: number;
    RegisterNum: number;
    PromotionTypeID: number;
    Domains: IPromotionLinkDetail[];
};

export type IPromotionLinkDetail = {
    GroupSequenceID: number;
    Sequence: number;
    Domain: string;
};

export type IColType = {
    label: string;
    name: string;
    type: ColType;
};

const PromotionLinkPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [copy] = useCopyToClipboardWithToast();
    const defaultAgentUserList = useSelector<RootState, string[]>((state) =>
        state.userStore.agentPartners
            .filter((item) => {
                const isPartnerEnable = getEnableConfig()
                    .map((v) => v.id)
                    .includes(item.PartnerId);
                return isPartnerEnable && item.IsEnabled;
            })
            .map((item) => item.AgentUserId.toString()),
    );
    const requestData: IBaseRequestData = baseRequest;
    const search = useMemo<TableSearch[]>(() => {
        return [
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ];
    }, []);

    const col = useMemo(
        () => [
            { label: 'Lbl_serialId', name: 'Id', type: ColType.PARTNER },
            { label: 'Th_partnerId', name: 'PartnerId', type: ColType.PARTNER },
            { label: 'Th_agentNickName', name: 'AgentNickName', type: ColType.TEXT },
            { label: 'Lbl_note', name: 'Remark', type: ColType.TEXT },
            { label: 'Lbl_domainName', name: 'DomainName', type: ColType.DOMAIN },
            { label: 'Th_copy', name: 'Copy', type: ColType.COPY },
            { label: 'Th_registerNum', name: 'RegisterNum', type: ColType.NUMBER },
            { label: 'Th_rechargeNum', name: 'RechargeNum', type: ColType.NUMBER },
            { label: 'Lbl_optional', name: 'Optional', type: ColType.OPTIONAL },
        ],
        [],
    );

    const [dataList, setDataList] = useState<IPromotionLink[]>([]);

    const handleRegisterMembers = useFetchApi<Partial<IPromotionLinkPara>, IPromotionLink[]>(
        api.getPromotionLink,
        ({ value }) => {
            setIsLoading(true);
            const obj: Record<string, IPromotionLinkPara[keyof IPromotionLinkPara] | undefined> = {};
            Object.keys(value).map((key) => {
                obj[toFirstUpperCase(key)] = value[key as keyof IPromotionLinkPara];
            });

            return { ...obj };
        },
        ({ value: { data } }) => {
            setIsLoading(false);
            if (data.IsSuccess) {
                setDataList(data.Data);
            } else {
                setDataList([]);
            }
        },
    );

    useEffect(() => {
        // first time to fetch;
        const obj: Record<string, IBaseRequestData[keyof IBaseRequestData]> = {};
        search.forEach((item) => {
            const key = toFirstUpperCase(item.matchKey!);
            if (key === 'UserIdList') {
                obj[key] = defaultAgentUserList;
            } else {
                return (obj[key] = requestData[key as keyof IBaseRequestData]);
            }
        });

        handleRegisterMembers(obj);
    }, []);

    const copyText = useCallback((text: string) => copy(text), []);

    const downPic = useCallback((id: string) => {
        // Generate download with use canvas and stream
        const canvas: HTMLCanvasElement = document.getElementById(id) as HTMLCanvasElement;
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `DQR-${Date.now()}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }, []);

    const [props, setProps] = useState<IQRCodePopupProps>({
        title: t('Lbl_GenQRCode'),
        domain: '',
        isOpen: false,
        onClose: () => {
            setProps((pre) => {
                return { ...pre, isOpen: false };
            });
        },
        onSave: () => {
            downPic('gen-QR');
        },
    });

    const [subDomainProps, setSubDomainProps] = useState<ISubdomainPopupProps>({
        title: '链接详情',
        domains: [],
        isOpen: false,
        onClose: () => {
            setSubDomainProps((pre) => {
                return { ...pre, isOpen: false };
            });
        },
        onGenQRCode: (domain) => {
            openPopup(domain);
        },
    });

    const openPopup = (text: string) => {
        setProps((pre) => {
            return { ...pre, isOpen: true, domain: text };
        });
    };

    const openSubDomainPopup = (domains: IPromotionLinkDetail[]) => {
        setSubDomainProps((pre) => {
            return { ...pre, isOpen: true, domains: domains };
        });
    };

    return (
        <>
            <div className="my-4">
                <TitleLabel>{t('Txt_promotionLink')}</TitleLabel>
            </div>

            <div>
                <DateRangeWithInput
                    defaultValue={requestData}
                    search={search}
                    handler={(data) => {
                        if (Object.hasOwn(data, 'userIdList') && data['userIdList']?.length === 0) {
                            dispatch(
                                showDialog({
                                    title: '警告',
                                    content: '必须选一个所属代理',
                                    type: DialogButtonType.Warning,
                                }),
                            );
                        } else {
                            handleRegisterMembers(data);
                        }
                    }} />

                <div data-container className="mx-4">
                    <TableContainer>
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    {col.map((item) => {
                                        return (
                                            <Th key={`userInfo-th-${item.label}`}>
                                                <span className="font-bold">{t(item.label)}</span>
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            </Thead>

                            <Tbody>
                                {!!dataList &&
                                    dataList.map((item, idx) => (
                                        <Tr key={`tr-key-${item.GroupSequenceID}-${item.AgentNickName}-${item.AgentNickName}`}>
                                            <Td>{idx + 1}</Td>
                                            <Td>{t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[item.PartnerId].text}`)}</Td>
                                            <Td>{item.AgentNickName || '-'}</Td>
                                            <Td>{item.Remark || '-'}</Td>
                                            <Td>
                                                {item.Domains.length === 1 ? (
                                                    <span>{item.Domains[0].Domain}</span>
                                                ) : (
                                                    <Button variant="link" className="" colorScheme="blue" onClick={() => openSubDomainPopup(item.Domains)}>
                                                        {item.Domains[0].Domain}
                                                    </Button>
                                                )}
                                            </Td>
                                            <Td>
                                                <Button colorScheme="orange" onClick={() => copyText(item.Domains[0].Domain)}>
                                                    {t('Lbl_copy')}
                                                </Button>
                                            </Td>
                                            <Td isNumeric>
                                                <div className="flex">{numberComma(item.RegisterNum)}</div>
                                            </Td>
                                            <Td align="center" isNumeric>
                                                <div className="flex">{numberComma(item.RechargeNum)}</div>
                                            </Td>
                                            <Td className="">
                                                <Button
                                                    variant="link"
                                                    className="mr-2"
                                                    colorScheme="blue"
                                                    onClick={() => downPic(`download-qr-code-${idx}`)}>
                                                    {t('Lbl_DownloadQRCode')}
                                                    <QRCodeCanvas
                                                        id={`download-qr-code-${idx}`}
                                                        className="hidden invisible"
                                                        value={item.Domains[0].Domain}
                                                        fgColor="black"
                                                        size={160}
                                                    />
                                                </Button>
                                                <Button variant="link" className="" colorScheme="blue" onClick={() => openPopup(item.Domains[0].Domain)}>
                                                    {t('Lbl_PreviewQRCode')}
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </div>
                <QRCodePopup {...props} key={`QR-${props.isOpen}`} />
                <SubdomainPopup {...subDomainProps} key={`SUB-${subDomainProps.isOpen}`} />
            </div>
            <LoadingMask visible={isLoading} />
        </>
    );
};

export default PromotionLinkPage;
