import { ForwardedRef, forwardRef, RefAttributes, useImperativeHandle, useState } from 'react';
import { APIModel } from '@/models/apiRequest';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { memberItineraryTableLayoutConfigs } from '@/components/memberItineraryModal/layouts';
import Pagination from '@/components/pagination';
import { useTranslation } from 'react-i18next';
import { MemberStatisticType, transformStatisticType, useMemberStatisticsData } from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import { IValueType } from '@/components/dateRangePicker';
import LoadingMask from '@/components/loadingMask/loadingMask';

export interface IMemberItineraryModalRef {
    onModelDataTrigger<T>(api: APIModel, request: T, layout: MemberStatisticType): void;
}

export interface IMemberItineraryProps extends RefAttributes<IMemberItineraryModalRef> {
    partnerId: number;
    userName: string;
    queryDateRange: IValueType;
}

export const MemberItineraryModal = forwardRef<IMemberItineraryModalRef, IMemberItineraryProps>(memberItineraryModal);

function memberItineraryModal({ partnerId, userName, queryDateRange }: IMemberItineraryProps, ref: ForwardedRef<IMemberItineraryModalRef>) {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentLayout, setLayout] = useState<MemberStatisticType>('');
    const [currentPage, setCurrentPage] = useState(0);

    const [statisticsData, fetchStatisticsData, isLoading] = useMemberStatisticsData({
        memberPartnerId: partnerId,
        memberUserName: userName,
        queryDateRange,
    });

    const pageCount = statisticsData ? ('TotalRecord' in statisticsData ? statisticsData.TotalRecord : statisticsData.Summary.TotalRecords) : 0;

    const layout = memberItineraryTableLayoutConfigs[currentLayout] ?? null;

    useImperativeHandle(ref, () => ({
        onModelDataTrigger: (api, request, layout) => {
            setLayout(layout);
            setCurrentPage(0);

            fetchStatisticsData({
                DateFrom: queryDateRange.startDate as string,
                DateTo: queryDateRange.endDate as string,
                PageNo: 1,
                PageSize: 10,
                PartnerId: partnerId,
                UserName: userName,
                StatisticType: transformStatisticType(layout),
            });
            onOpen();
        },
    }));

    function onSetPage(page: number) {
        setCurrentPage(page);
        fetchStatisticsData({
            DateFrom: queryDateRange.startDate as string,
            DateTo: queryDateRange.endDate as string,
            PageNo: page + 1,
            PageSize: 10,
            PartnerId: partnerId,
            UserName: userName,
            StatisticType: transformStatisticType(currentLayout),
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="flex items-center rounded-t">
                    <div className="flex-1 text-center">{layout?.layoutName && t(layout.layoutName)}</div>

                    <button type="button" onClick={onClose}>
                        <span className="icon icon-cancel-circle" />
                    </button>
                </ModalHeader>

                <ModalBody className="flex flex-col gap-4">
                    <div>{t('Lbl_date_range') + '：' + queryDateRange.startDate + ' - ' + queryDateRange.endDate}</div>

                    {layout && statisticsData && (
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    {layout.headers.map((h, index) => (
                                        <Th key={`mimth-${index}`}>{t(h)}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {layout.collectionGetter(statisticsData).map((d, index) => (
                                    <Tr key={`mimtr-${index}`}>
                                        {layout.contentBuilder(d, statisticsData).map((cell, cellIndex) => (
                                            <Td key={`mimtc-${cellIndex}`}>{typeof cell === 'function' ? cell(t) : cell}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    {layout
                                        .footerBuilder?.(statisticsData)
                                        .map((cell, index) => <Td key={`mimtf-${index}`}>{typeof cell === 'function' ? cell(t) : cell}</Td>)}
                                </Tr>
                            </Tfoot>
                        </Table>
                    )}

                    <div className="flex gap-4 items-center justify-center">
                        <div>{t('Lbl_TotalRecord', { '0': pageCount })}</div>

                        {pageCount > 0 && (
                            <Pagination defaultPage={currentPage} pageCount={Math.ceil(pageCount / 10)} onPageChange={({ selected }) => onSetPage(selected)} />
                        )}
                    </div>
                </ModalBody>

                <LoadingMask visible={isLoading} />
            </ModalContent>
        </Modal>
    );
}
