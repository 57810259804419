import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { VIPTagComp } from '@/components/vipTagComp/VIPTagComp';
import { IMemberDetailsRepoprtSummary } from '@/models/page/report/IReportMemberDetails';
import BettingRateNote from '@/components/bettingRateNote/bettingRateNote';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';

type RepoprtSummary = {
    RelatedReportSummary: IMemberDetailsRepoprtSummary[];
    SelfReportSummary: IMemberDetailsRepoprtSummary[];
    RelatedMemberCount: number;
};

export const RelationAccountTabs = (repoprtSummary: RepoprtSummary) => {
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const { t } = useTranslation();

    const Note = React.memo(() => (
        <div data-note className="m-4 text-xs w-[600px]">
            <ul className="ml-4 list-disc ">
                <li className="text-red-400 whitespace-pre-line">未下注天数: 统计会员当前时间距离最后一次下注时间的自然天数;</li>
                <li className="text-red-400 whitespace-pre-line">
                    投注率: 月投注金额/月储值金额(充值+代充)， 客户活跃度获愿意参与投注率的指标。计算后将分成以下五个等级
                </li>
            </ul>
            <div className="my-2">
                <BettingRateNote />
            </div>
        </div>
    ));
    const _table = (summaryList: IMemberDetailsRepoprtSummary[], type: string) => {
        const resultList = summaryList.sort((a, b) => a.ReportMonth - b.ReportMonth);
        return (
            <div className="flex">
                <table className="border-collapse border border-slate-400 text-sm">
                    <tbody>
                        <tr className="border border-slate-400 text-center">
                            <td className="bg-gray-300 p-1">{t('Th_month')}</td>
                            {resultList.map((item) => {
                                return (
                                    <td className="bg-gray-300 border border-slate-400 p-1 text-center" key={`${type}-M-${item.ReportMonth}`}>
                                        {t(`Lbl_M_${item.ReportMonth}`)}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr className="border border-slate-400 p-1 text-center">
                            <td className="bg-gray-300 p-1">{t('Lbl_Activity')}</td>
                            {resultList.map((item) => {
                                return (
                                    <td className="bg-gray-100 border border-slate-400 p-1" key={`${type}-Activity-${item.ReportMonth}`}>
                                        <span className={`icon ${item.IsActive ? 'icon-checkmark' : ''}`} />
                                    </td>
                                );
                            })}
                        </tr>
                        <tr className="text-center">
                            <td className="bg-gray-300 p-1">{t('Th_BettingRate')}</td>
                            {resultList.map((item) => {
                                return (
                                    <td className="bg-gray-100 border border-slate-400 p-1" key={`${type}-BettingRate-${item.ReportMonth}`}>
                                        <VIPTagComp bettingRate={item.BetRate} className="text-xs" />
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
                <div className="ml-2">
                    <Popover placement="left-start" matchWidth={true} offset={[-10, 660]}>
                        <PopoverTrigger>
                            <span className="text-lg text-orange-300 icon icon-question" />
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent width="2xl">
                                <PopoverArrow />

                                <PopoverBody>
                                    <Note />
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                </div>
            </div>
        );
    };

    return (
        <>
            <Tabs size="md" variant="enclosed" borderColor="gray.400">
                <TabList>
                    <Tab _selected={{ color: 'gray.50', bg: 'gray.500' }} borderColor="gray.400" fontSize="small">
                        {t('Lbl_SelfAccount')}
                    </Tab>
                    {permissionList.includes(PermissionsId.RelatedMembers) && (
                        <Tab _selected={{ color: 'gray.50', bg: 'gray.500' }} borderColor="gray.400" fontSize="small">
                            {t('Lbl_RelationAccount')} {`(${repoprtSummary.RelatedMemberCount})`}
                        </Tab>
                    )}
                </TabList>
                <TabPanels className="border border-gray-400">
                    <TabPanel>
                        <div className="flex justify-around">
                            <div className="flex flex-1 justify-center items-center">
                                <p className="font-bold">{t('Txt_Past6M_Report')}:</p>
                            </div>
                            <div className="flex-1">{_table(repoprtSummary.SelfReportSummary, 'Self')}</div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="flex justify-around">
                            <div className="flex flex-1 justify-center items-center">
                                <p className="font-bold">{t('Txt_Past6M_Report')}:</p>
                            </div>
                            <div className="flex-1">{_table(repoprtSummary.RelatedReportSummary, 'Related')}</div>
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};
