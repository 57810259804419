import { NumberHighlight } from '@/components/numberHighlight/numberHighlight';
import { TableColumnStyle } from '@/models/layout/tableLayout';
import { parseCommissionType } from '@/service/utils';
import { Box, Divider, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberDetailsLayoutProps } from '..';
import { MemberDetailsLayout } from './layout.mapping';

const MemberDetailsMain = ({ isLoaded, statistics, setLayout }: MemberDetailsLayoutProps) => {
    const { t } = useTranslation();

    const parseWinLose = useCallback((targetNum = 0) => {
        return <NumberHighlight value={targetNum} />;
    }, []);
    return (
        <>
            <SimpleGrid columns={2} spacingX="40px" spacingY="10px" className="mt-3">
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <Text fontSize="sm" className="py-2">
                            {`${t('Lbl_FirstRechargeSum')} : ${
                                statistics?.FirstRecharge && parseCommissionType(statistics.FirstRecharge.AmountSummary, TableColumnStyle.CommaNumeric)
                            }`}
                        </Text>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <div
                            className="flex items-center justify-between cursor-pointer hover:text-blue-500 py-2"
                            onClick={() => setLayout(MemberDetailsLayout.RECHARGE)}>
                            <Text fontSize="sm">
                                {`${t('Th_effectAmountSum')} : `}
                                {statistics?.Recharge && parseCommissionType(statistics.Recharge.AmountSummary, TableColumnStyle.CommaNumeric)}
                            </Text>
                            <span className="icon icon-keyboard_arrow_right" />
                        </div>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <div
                            className="flex items-center justify-between cursor-pointer hover:text-blue-500 py-2"
                            onClick={() => setLayout(MemberDetailsLayout.DEPOSIT)}>
                            <Text fontSize="sm">
                                {`${t('Th_rechargeSum')} : `}
                                {statistics?.Deposit && parseCommissionType(statistics.Deposit.AmountSummary, TableColumnStyle.CommaNumeric)}
                            </Text>
                            <span className="icon icon-keyboard_arrow_right" />
                        </div>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <div
                            className="flex items-center justify-between cursor-pointer hover:text-blue-500 py-2"
                            onClick={() => setLayout(MemberDetailsLayout.WITHDRAW)}>
                            <Text fontSize="sm">
                                {`${t('Th_withdrawAmount')} : `}
                                {statistics?.Withdraw && parseCommissionType(statistics.Withdraw.AmountSummary, TableColumnStyle.CommaNumeric)}
                            </Text>
                            <span className="icon icon-keyboard_arrow_right" />
                        </div>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <Text className="py-2" fontSize="sm">
                            {`${t('Th_rebeat')} :  ${
                                statistics?.Rebate && parseCommissionType(statistics?.Rebate.AmountSummary, TableColumnStyle.CommaNumeric)
                            }`}
                        </Text>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <Text className="py-2" fontSize="sm">
                            {`${t('Th_bonus')} :  ${statistics?.Bonus && parseCommissionType(statistics.Bonus.AmountSummary, TableColumnStyle.CommaNumeric)}`}
                        </Text>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <div
                            className="flex items-center justify-between cursor-pointer hover:text-blue-500 py-2"
                            onClick={() => setLayout(MemberDetailsLayout.WINLOSE)}>
                            <Text fontSize="sm">
                                {`${t('Th_betAmount')} : ${
                                    statistics?.BetAmount && parseCommissionType(statistics?.BetAmount.AmountSummary, TableColumnStyle.CommaNumeric)
                                }`}
                            </Text>
                            <span className="icon icon-keyboard_arrow_right" />
                        </div>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
                <Skeleton height="40px" isLoaded={isLoaded}>
                    <Box height="40px">
                        <div
                            className="flex items-center justify-between cursor-pointer hover:text-blue-500 py-2"
                            onClick={() => setLayout(MemberDetailsLayout.WINLOSE)}>
                            <Text fontSize="sm">
                                {`${t('Th_totalWin')} : `}
                                {parseWinLose(statistics?.WinLose.AmountSummary)}
                            </Text>
                            <span className="icon icon-keyboard_arrow_right" />
                        </div>
                        <Divider orientation="horizontal" />
                    </Box>
                </Skeleton>
            </SimpleGrid>
        </>
    );
};

export default MemberDetailsMain;
