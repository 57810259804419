import { ICurrencyTypeEnumValue, CurrencyTypeEnum } from '@/models/page/recharge/IRechargeMonitor';

export const CurrencyMemberComp = (props: { currencyType: ICurrencyTypeEnumValue; userName: string; onClick: () => void }) => {
    const colorMapping = {
        [CurrencyTypeEnum.RMB]: 'bg-yellow-600 hover:bg-blue-200',
        [CurrencyTypeEnum.USDT]: 'bg-green-600 hover:bg-orange-200',
        [CurrencyTypeEnum.UnKnown]: 'bg-red-600 hover:bg-orange-200',
    };

    return (
        <button className={`self-end p-1 text-white rounded mr-1 mt-1 ${colorMapping[props.currencyType]}`} onClick={props.onClick}>
            <label className="px-1 cursor-pointer">{props.userName}</label>
            <span className="px-1 icon icon-cancel-circle" />
        </button>
    );
};
